import { last, text } from "./util.js";
import { toCoordinate } from "./activity.js";
import { duration } from "./time.js";

/**
 * @typedef {import('./activity.js').Coordinate} Coordinate
 */

/**
 * @typedef GpxOptions
 * @property {boolean=} fillGaps whether or not to fill in gaps between segments
 * @property {import('xmldom').DOMParser=} domparser pass an instance of DOMParser so we can work in
 * the browser or node
 */

/**
 * @param {GpxOptions & { xml: string }} options
 * @return {import('./activity.js').Activity}
 */
function fromGpx({ xml, fillGaps = true, domparser: inDomparser }) {
  let domparser = inDomparser;

  if (!domparser) {
    if (typeof window.DOMParser !== "undefined") {
      domparser = new window.DOMParser();
    } else {
      throw new Error("could not load DOMParser");
    }
  }

  const dom = domparser.parseFromString(xml, "text/xml");

  /** @type {import('./activity.js').Segment[]} */
  const segments = [];
  /** @type {import('./activity.js').Marker[]} */
  const markers = [];

  let title;
  let description;

  const trks = dom.getElementsByTagName("trk");
  if (trks.length) {
    const trk = trks[0];
    const names = trk.getElementsByTagName("name");
    if (names.length) {
      title = names[0].textContent?.trim();
    }
    const descs = trk.getElementsByTagName("desc");
    if (descs.length) {
      description = descs[0].textContent?.trim();
    }
  }

  let movingDuration = 0;
  let totalDuration = 0;

  Array.from(dom.getElementsByTagName("trkseg")).forEach((trkseg) => {
    /** @type {Coordinate[]} */
    const coordinates = [];

    Array.from(trkseg.getElementsByTagName("trkpt")).forEach((trkpt) => {
      const long = Number(trkpt.getAttribute("lon"));
      const lat = Number(trkpt.getAttribute("lat"));
      const timestamp = new Date(String(trkpt.getElementsByTagName("time")[0]?.textContent));

      if (long && lat) {
        coordinates.push(toCoordinate({ long, lat, timestamp }));
      }
    });

    const firstCoordinate = coordinates[0];
    const lastCoordinate = last(coordinates);

    if (firstCoordinate !== undefined) {
      markers.push({ type: "start", coordinate: firstCoordinate });
    }

    if (lastCoordinate !== undefined) {
      markers.push({ type: "stop", coordinate: lastCoordinate });
    }

    if (firstCoordinate && lastCoordinate) {
      const [, , startTime] = firstCoordinate;
      const [, , endTime] = lastCoordinate;
      if (startTime && endTime) {
        movingDuration += duration({ startTime, endTime });
      }
    }

    const lastSegment = last(segments);
    if (firstCoordinate !== undefined) {
      if (lastSegment?.coordinates.length && fillGaps) {
        segments.push({
          coordinates: [last(lastSegment.coordinates), firstCoordinate],
          type: "filler",
        });
      }

      segments.push({ coordinates, type: "active" });
    }
  });

  let startTime;
  if (segments.length) {
    if (segments[0].coordinates.length) {
      [[, , startTime]] = segments[0].coordinates;
    }
    const lastSegment = last(segments);
    if (lastSegment && lastSegment.coordinates.length) {
      const [, , endTime] = last(lastSegment.coordinates);
      if (startTime && endTime) {
        totalDuration = duration({ startTime, endTime });
      }
    }
  }

  return {
    markers,
    segments,
    title,
    description,
    startTime: startTime?.valueOf(),
    movingDuration,
    totalDuration,
  };
}

/**
 * @param {import('./util.js').BufferLikeOrString} input
 * @param {GpxOptions=} options
 * @return {Promise<import('./activity.js').Activity>}
 */
function parseGpxToActivity(input, options = {}) {
  return text(input).then((str) => fromGpx({ xml: str, ...options }));
}

export { parseGpxToActivity, fromGpx };
