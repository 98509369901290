/* eslint-disable camelcase */
import polyline from "@mapbox/polyline";
import { toCoordinate } from "./activity.js";

/**
 * @typedef {import('./fetch.js').WithFetch} WithFetch
 */

/**
 * An Activity from Strava. Different from our shared Activity in activity.js
 * @typedef Activity
 * @property {number} id
 * @property {string} name
 * @property {string=} description
 * @property {ISO8601} start_date
 * @property {ISO8601} start_date_local
 * @property {number} elapsed_time seconds
 * @property {number} moving_time seconds
 * @property {LatLng=} start_latlng
 * @property {LatLng=} end_latlng
 * @property {Map=} map
 * @property {SegmentEffort[]} segment_efforts
 */

/**
 * @typedef {string} ISO8601
 */

/**
 * @typedef {[number,number]} LatLng
 */

/**
 * @typedef Map
 * @property {string} id
 * @property {string?} polyline
 * @property {string?} summary_polyline
 */

/**
 * @typedef SegmentEffort
 * @property {number} id
 * @property {string} name
 * @property {Segment} segment
 */

/**
 * @typedef Segment
 * @property {number} id
 * @property {LatLng} start_latlng
 * @property {LatLng} end_latlng
 * @property {Map?} map
 */

/**
 * @param {string|undefined|null} userAgent
 * @return {string}
 */
function platformAuthorizeUrl(userAgent) {
  if (userAgent && /(iPhone|Android)/i.test(userAgent)) {
    return "https://www.strava.com/oauth/mobile/authorize";
  }
  return "https://www.strava.com/oauth/authorize";
}

/**
 * @typedef AuthorizeParams
 * @property {string} clientId
 * @property {string|undefined|null} userAgent
 * @property {string|URL} redirectUri
 * @property {string=} state
 */

/**
 * @param {AuthorizeParams} params
 * @return {URL}
 */
function authorizeUrl({ clientId, userAgent, redirectUri, state }) {
  const url = new URL(platformAuthorizeUrl(userAgent));

  url.searchParams.set("client_id", clientId);
  url.searchParams.set("redirect_uri", redirectUri.toString());
  url.searchParams.set("response_type", "code");
  url.searchParams.set("approval_prompt", "auto");
  url.searchParams.set("scope", "activity:read_all,read_all");
  url.searchParams.set("state", String(state));

  return url;
}

/**
 * @typedef AccessTokenRequest
 * @property {string} clientId
 * @property {string} clientSecret
 * @property {string} code
 */

/**
 * @typedef AccessTokenResponse
 * @property {string} accessToken
 * @property {Date} expiresAt
 * @property {number} expiresIn number of seconds
 */

/**
 * @param {AccessTokenRequest & WithFetch} request
 * @return {Promise<AccessTokenResponse>}
 */
function getAccessToken({ clientId, clientSecret, code, fetch = window.fetch }) {
  const params = new URLSearchParams();
  params.set("client_secret", clientSecret);

  return fetch("https://www.strava.com/api/v3/oauth/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      code,
      grant_type: "authorization_code",
    }),
  })
    .then((response) => response.json())
    .then(({ access_token, expires_at, expires_in, message }) => {
      if (!access_token || !expires_in) {
        throw new Error(`failed to load access token: ${message}`);
      }

      return {
        accessToken: access_token,
        expiresIn: expires_in,
        expiresAt: new Date(expires_at * 1000),
      };
    });
}

/**
 * @typedef GetLoggedInAthleteActivitiesRequest
 * @property {string} accessToken
 * @property {number=} page
 * @property {number=} perPage
 */

/**
 * @typedef GetLoggedInAthleteActivitiesResponse
 * @property {number} page the current page number
 * @property {Activity[]} activities
 */

/**
 * @param {GetLoggedInAthleteActivitiesRequest & WithFetch} request
 * @return {Promise<GetLoggedInAthleteActivitiesResponse>}
 */
function getLoggedInAthleteActivities({ accessToken, page, perPage, fetch = window.fetch }) {
  const requestPage = Number(page) || 1;

  const url = new URL("https://www.strava.com/api/v3/athlete/activities");
  url.searchParams.set("page", String(requestPage));
  if (perPage) {
    url.searchParams.set("per_page", String(perPage));
  }

  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(`Got response status ${response.status}`);
    })
    .then((activities) => ({
      page: requestPage,
      activities,
    }));
}

/**
 * @typedef GetActivityRequest
 * @property {string} accessToken
 * @property {string|number} activityId
 */

/**
 * @param {GetActivityRequest & WithFetch} request
 * @return {Promise<Activity>}
 */
function getActivity({ accessToken, activityId, fetch = window.fetch }) {
  const url = new URL(`https://www.strava.com/api/v3/activities/${activityId}`);
  url.searchParams.set("include_all_efforts", String(true));

  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(`Got response status ${response.status}`);
  });
}

/**
 * @typedef ActivityStreamsRequest
 * @property {import('./strava-types').ActivityStreamType[]=} keys
 */

/**
 * @param {GetActivityRequest & ActivityStreamsRequest & WithFetch} request
 * @return {Promise<import('./strava-types').ActivityStream[]>}
 */
function getActivityStreams({
  accessToken,
  activityId,
  keys = ["distance", "time", "latlng"],
  fetch = window.fetch,
}) {
  const url = new URL(`https://www.strava.com/api/v3/activities/${activityId}/streams`);
  url.searchParams.set("keys", keys.join(","));
  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(`Got response status ${response.status}`);
  });
}

/**
 * @typedef GetSegmentRequest
 * @property {string} accessToken
 * @property {string|number} segmentId
 */

/**
 * @param {GetSegmentRequest & WithFetch} request
 * @return {Promise<Segment>}
 */
function getSegment({ accessToken, segmentId, fetch = window.fetch }) {
  const url = new URL(`https://www.strava.com/api/v3/segments/${segmentId}`);
  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(`Got response status ${response.status}`);
  });
}

/**
 * @param {import('./activity.js').Marker[]} markers
 * @param {LatLng|null|undefined} latlng
 * @param {import('./activity.js').MarkerType} type
 */
function pushIfMarker(markers, latlng, type) {
  if (!Array.isArray(latlng)) {
    return;
  }

  const [lat, long] = latlng;
  markers.push({
    type,
    coordinate: toCoordinate({ long, lat }),
  });
}

/**
 * @param {Map} map
 * @param {import('./activity.js').SegmentType} type
 * @return {import('./activity.js').Segment}
 */
function mapToSegment(map, type) {
  const path = map.polyline || map.summary_polyline || "";

  const coordinates = polyline.decode(path).map(([lat, long]) => toCoordinate({ long, lat }));

  return {
    coordinates,
    type,
  };
}

/**
 * @typedef ToActivityOptions
 * @property {Segment[]=} stravaSegments
 * @property {import('./strava-types').TimeStream=} timeStream
 * @property {import('./strava-types').LatLngStream=} latLngStream
 * @property {import('./strava-types').DistanceStream=} distanceStream
 */

/**
 * @param {Activity} activity
 * @param {ToActivityOptions=} options
 * @return {import('./activity.js').Activity}
 * Converts Strava activity to share Activity representation
 */
function toActivity(activity, { stravaSegments = [], timeStream, latLngStream } = {}) {
  /** @type import('./activity.js').Segment[] */
  const segments = [];

  if (
    activity.start_date &&
    latLngStream &&
    timeStream &&
    latLngStream.original_size === timeStream.original_size
  ) {
    /** @type {import('./activity').Coordinate[]} */
    const coordinates = [];
    const start = new Date(activity.start_date);
    for (let idx = 0, len = latLngStream.original_size; idx < len; ++idx) {
      const [lat, long] = latLngStream.data[idx];
      const timestamp = new Date(+start + timeStream.data[idx] * 1000);
      coordinates.push(
        toCoordinate({
          lat,
          long,
          timestamp,
        })
      );
    }
    segments.push({
      type: "active",
      coordinates,
    });
  } else if (activity.map?.summary_polyline || activity.map?.polyline) {
    segments.push(mapToSegment(activity.map, "active"));
  }

  /** @type import('./activity.js').Marker[] */
  const markers = [];

  pushIfMarker(markers, activity.start_latlng, "start");
  pushIfMarker(markers, activity.end_latlng, "stop");

  if (activity.segment_efforts) {
    activity.segment_efforts.forEach((segmentEffort) => {
      pushIfMarker(markers, segmentEffort?.segment?.start_latlng, "shared_start");
      pushIfMarker(markers, segmentEffort?.segment?.end_latlng, "shared_stop");
    });
  }

  Array.from(stravaSegments).forEach((segment) => {
    if (segment.map) {
      segments.push(mapToSegment(segment.map, "shared"));
    }
  });

  const startTime = new Date(activity.start_date).valueOf();

  return {
    segments,
    markers,
    startTime,
    movingDuration: activity.moving_time,
    totalDuration: activity.elapsed_time,
    title: activity.name,
    description: activity.description,
  };
}

export {
  authorizeUrl,
  getAccessToken,
  getLoggedInAthleteActivities,
  getActivity,
  getActivityStreams,
  getSegment,
  toActivity,
};
