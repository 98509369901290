import React, { useState, useEffect } from "react";

/**
 * @typedef Dimensions
 * @property {number} width
 * @property {number} height
 */

/**
 * @return {Dimensions}
 */
function currentWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

/**
 * @typedef FullScreenProps
 * @property {boolean} enabled
 * @property {import('react').ReactElement} children
 * @property {()=>void} didClose
 */

/**
 * @param {FullScreenProps} props
 * @return {JSX.Element}
 */
function FullScreen({ enabled, children, didClose }) {
  return (
    <div className={enabled ? "fullscreen" : ""}>
      {enabled && (
        <button type="button" className="close-button" onClick={() => didClose()} title="Close">
          X
        </button>
      )}
      {children}
    </div>
  );
}

/**
 * Returns a useState() that is wired to be updated when the window resizes
 * @return {[Dimensions, (dim:Dimensions)=>void]}
 */
function useWindowDimensionsEffectState() {
  const [dimensions, setWindowDimensions] = useState(currentWindowDimensions());

  useEffect(() => {
    const listener = () => {
      setWindowDimensions(currentWindowDimensions());
    };

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  });

  return [dimensions, setWindowDimensions];
}

export { FullScreen, useWindowDimensionsEffectState };
