import React, { useState } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Strava from "../lib/strava.js";
import { parseCsvToActivity } from "../lib/csv.js";
import { parseFitToActivity } from "../lib/fit.js";
import { parseGpxToActivity } from "../lib/gpx.js";
import { parseTcxToActivity } from "../lib/tcx.js";
import { boundingBox, scaleBoundingBox, projectAndGetBounds } from "../lib/map.jsx";
import { getCookie } from "./cookie.js";
import { mapboxTiles } from "./mapbox.js";
import MapWithControls from "./map-with-controls.jsx";
import { garminRequestToken, ENDPOINTS } from "../lib/sportmap.club.js";
import * as Garmin from "../lib/garmin.js";
import { buildNonce } from "../lib/random.js";
import ActivityList from "./activity-list.jsx";
import ActivityDetail from "./activity-detail.jsx";
import { WithCookies } from "./cookie-context.jsx";
import ScrollToTop from "./scroll-to-top.jsx";
import Chart from "./chart.jsx";
import { activityHasTimestamps } from "../lib/activity.js";

/**
 * @typedef FileChangedProps
 * @property {(activity:Activity[])=>void} setActivities
 * @property {(arr:[mapboxFeatures:FeatureCollection, bbox:BoundingBox])=>void} setMapboxFeatures
 * @property {number} width
 * @property {number} height
 */

/**
 * @param {FileChangedProps} props
 * @return {(event:import('react').ChangeEvent<HTMLInputElement>)=>void}
 */
function fileChanged({ setActivities, setMapboxFeatures, width, height }) {
  return (event) => {
    const input = /** @type {HTMLInputElement?} */ event.target;

    if (input?.files?.length) {
      /** @type {Promise<Activity>[]} */
      const parsers = [];

      Array.from(input.files).forEach((file) => {
        if (/\.csv$/i.test(file.name)) {
          parsers.push(parseCsvToActivity(file));
        } else if (/\.gpx/i.test(file.name)) {
          parsers.push(parseGpxToActivity(file));
        } else if (/\.fit$/i.test(file.name)) {
          parsers.push(parseFitToActivity(file));
        } else if (/\.tcx$/i.test(file.name)) {
          parsers.push(parseTcxToActivity(file));
        }
      });

      if (parsers.length) {
        Promise.all(parsers).then((activities) => {
          setActivities(activities);

          const [, bbox] = projectAndGetBounds({ activities, width, height });

          mapboxTiles({
            bbox,
            extraTiles: 0,
          }).then((mapboxFeatures) => {
            setMapboxFeatures([mapboxFeatures, bbox]);
          });
        });
      }
    }
  };
}

/**
 * @typedef {import('../lib/activity.js').Activity} Activity
 * @typedef {import('geojson').FeatureCollection} FeatureCollection
 * @typedef {import('../lib/map.jsx').BoundingBox} BoundingBox
 */

/**
 * @typedef Dimensions
 * @property {number} width
 * @property {number} height
 */

/**
 * @typedef IndexPageProps
 * @property {number} width
 * @property {boolean=} garminConnectEnabled
 */

/**
 * @param {IndexPageProps} props
 * @return {JSX.Element}
 */
function IndexPage({ width, garminConnectEnabled = false }) {
  const [activities, setActivities] = useState(/** @type {Activity[]} */ ([]));
  const [[mapboxFeatures, mapboxBbox], setMapboxFeatures] = useState(
    /** @type {[FeatureCollection?, BoundingBox?]} */ ([undefined, undefined])
  );

  let stravaUrl;
  const stravaAccessToken = getCookie("stravaAccessToken");

  if (stravaAccessToken) {
    stravaUrl = "/strava";
  } else {
    const stravaRedirectUri = new URL(document.location.toString());
    stravaRedirectUri.pathname = ENDPOINTS.STRAVA.CALLBACK;

    stravaUrl = Strava.authorizeUrl({
      clientId: String(process.env.STRAVA_CLIENT_ID),
      userAgent: window.navigator.userAgent,
      state: buildNonce(),
      redirectUri: stravaRedirectUri,
    }).toString();
  }

  /**
   * @type {import('react').MouseEventHandler<HTMLAnchorElement>}
   */
  function garminAuthorize(event) {
    event.preventDefault();

    const garminRedirectUri = new URL(document.location.toString());
    garminRedirectUri.pathname = ENDPOINTS.GARMIN.CALLBACK;

    garminRequestToken({ fetch }).then(({ requestToken, requestTokenSecret }) => {
      garminRedirectUri.searchParams.set("request_token_secret", requestTokenSecret);

      document.location.assign(
        Garmin.authorizeUrl({
          requestToken,
          oauthCallback: garminRedirectUri,
        }).toString()
      );
    });
  }

  const bbox = activities.length && scaleBoundingBox(1.2, boundingBox(activities));
  const height = 400;

  const debugRects = [];
  if (bbox) {
    debugRects.push(bbox);
  }
  if (mapboxBbox) {
    debugRects.push(mapboxBbox);
  }

  return (
    <>
      {!!activities.length && bbox && (
        <MapWithControls
          width={width}
          activities={activities}
          height={height}
          bbox={bbox}
          debugRects={debugRects}
          mapboxFeatures={mapboxFeatures}
          disableSharedSegmentsToggle
        />
      )}
      {!!activities.length &&
        activities
          .filter(activityHasTimestamps)
          .map((activity) => <Chart activity={activity} width={width} />)}
      <div className="dropzone" draggable>
        <div className="dropzone-info">
          Drop a <code>.FIT</code>, <code>.GPX</code>, <code>.TCX</code>, or <code>.CSV</code> here
          to see it rendered as an SVG!
        </div>

        <label>
          Select a file from your machine
          <input
            type="file"
            name="user-file"
            multiple
            onChange={fileChanged({
              setActivities,
              setMapboxFeatures,
              width,
              height,
            })}
          />
        </label>
      </div>

      <div className="sign-in-with">Or sign in with</div>

      <a className="big-button button-strava" href={stravaUrl}>
        Strava
      </a>

      {garminConnectEnabled && (
        <a className="big-button button-garmin" href="/garmin" onClick={garminAuthorize}>
          Garmin Connect
        </a>
      )}
    </>
  );
}

/**
 * @typedef AppProps
 * @property {number} screenWidth
 */

/**
 * @param {AppProps} props
 * @return {JSX.Element}
 */
function App({ screenWidth }) {
  return (
    <Router>
      <Switch>
        <Route path={String(ENDPOINTS.STRAVA.DETAIL)}>
          <WithCookies cookieNames={["stravaAccessToken"]}>
            <ActivityDetail.Strava width={screenWidth} height={400}>
              <ActivityDetail width={screenWidth} height={400} />
            </ActivityDetail.Strava>
          </WithCookies>
        </Route>
        <Route
          path={ENDPOINTS.STRAVA.LIST}
          render={({ location }) => (
            <ScrollToTop>
              <WithCookies cookieNames={["stravaAccessToken"]}>
                <ActivityList.Strava location={location}>
                  <ActivityList />
                </ActivityList.Strava>
              </WithCookies>
            </ScrollToTop>
          )}
        />
        <Route path={String(ENDPOINTS.GARMIN.DETAIL)}>GARMIN ID</Route>
        <Route path={ENDPOINTS.GARMIN.LIST}>GARMIN</Route>
        <Route path={ENDPOINTS.INDEX}>
          <IndexPage
            width={screenWidth}
            garminConnectEnabled={String(process.env.GARMIN_OAUTH_ENABLED) === "true"}
          />
        </Route>
      </Switch>
    </Router>
  );
}

window.addEventListener("DOMContentLoaded", () => {
  const rootElem = document.getElementById("root-elem");
  if (rootElem) {
    render(<App screenWidth={rootElem.offsetWidth} />, rootElem);
  }
});
