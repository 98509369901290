// Browser port of https://github.com/mapbox/vt2geojson
import zlib from "pako";
import Protobuf from "pbf";
import { VectorTile } from "@mapbox/vector-tile";

/**
 * @typedef ReadTileProps
 * @property {Uint8Array} buffer
 * @property {string[]=} layers
 * @property {number} x
 * @property {number} y
 * @property {number} z
 */
/**
 * @param {ReadTileProps} props
 * @return {import('geojson').FeatureCollection}
 */
function readTile({ buffer, layers, x, y, z }) {
  const tile = new VectorTile(new Protobuf(buffer));

  const keys = Array.isArray(layers) && layers.length ? layers : Object.keys(tile.layers);

  /** @type {import('geojson').Feature[]} */
  const features = [];

  keys.forEach((key) => {
    const layer = tile.layers[key];

    for (let i = 0; i < layer.length; i += 1) {
      const elem = layer.feature(i);

      const feature = elem.toGeoJSON(x, y, z);
      feature.properties.layer = key;

      features.push(feature);
    }
  });

  return {
    type: "FeatureCollection",
    features,
  };
}

/**
 * @param {ArrayBuffer} buffer
 * @return {Uint8Array}
 */
function decompress(buffer) {
  const array = new Uint8Array(buffer);

  // handle zipped buffers
  if (array[0] === 0x78 && array[1] === 0x9c) {
    return zlib.inflate(array);
  }
  if (array[0] === 0x1f && array[1] === 0x8b) {
    return zlib.ungzip(array);
  }

  return array;
}

/**
 * @typedef Vt2GeoJSONProps
 * @property {URL|string} url
 * @property {(string|string[])=} layers
 * @property {number} x
 * @property {number} y
 * @property {number} z
 */

/**
 * @param {Vt2GeoJSONProps} props
 * @return {Promise<import('geojson').FeatureCollection>}
 */
function vt2geojson({ url, layers, x, y, z }) {
  /** @type {string[]} */
  let layersArr = [];
  if (typeof layers === "string") {
    layersArr = [layers];
  } else if (Array.isArray(layers)) {
    layersArr = layers;
  }

  return window.fetch(String(url)).then((response) => {
    if (response.ok) {
      return response.arrayBuffer().then((arrayBuffer) =>
        readTile({
          buffer: decompress(arrayBuffer),
          layers: layersArr,
          x,
          y,
          z,
        })
      );
    }
    throw new Error("bad response :[");
  });
}

export { vt2geojson };
