import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ENDPOINTS } from "../lib/sportmap.club.js";
import { CookieContext } from "./cookie-context.jsx";
import { Map } from "../lib/map.jsx";
import Timestamp from "./timestamp.jsx";
import { MapStyles } from "../lib/map-style.js";
import * as Strava from "../lib/strava.js";

/** @typedef {import('../lib/activity.js').Activity} Activity */

/**
 * @typedef ActivityWithMetadata
 * @property {Activity} activity
 * @property {string} title
 * @property {Date=} timestamp
 * @property {string} detailUrl
 */

/**
 * @return {JSX.Element}
 */
function ActivityPlaceholder() {
  return (
    <div className="activity-summary activity-placeholder">
      Loading…
      <div className="small-timestamp">Loading…</div>
      <div className="map-placeholder" />
    </div>
  );
}

const ActivityListContext = createContext({
  activitiesPromise: /** @type Promise<ActivityWithMetadata[]>? */ (null),
  olderPage: /** @type {string?} */ (null),
  newerPage: /** @type {string?} */ (null),
});

/**
 * @typedef ActivityListItemProps
 * @property {string} title
 * @property {string} detailUrl
 * @property {Date=} timestamp
 * @property {Activity} activity
 */

/**
 * @param {ActivityListItemProps} props
 * @return {JSX.Element}
 */
function ActivityListItem({ detailUrl, title, timestamp, activity }) {
  const ref = useRef(/** @type HTMLDivElement? */ (null));
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return (
    <div className="activity-summary" ref={ref}>
      <Link to={detailUrl}>{title}</Link>
      <div className="small-timestamp">
        {timestamp && <Timestamp timestamp={timestamp} showWeekday />}
      </div>
      <div>
        {activity.segments.length ? (
          <Map activities={[activity]} width={width} height={100} mapStyle={MapStyles.NO_TERRAIN} />
        ) : undefined}
      </div>
    </div>
  );
}

/**
 * @return {JSX.Element}
 */
function ActivityList() {
  const { activitiesPromise, newerPage, olderPage } = useContext(ActivityListContext);

  const [activities, setActivities] = useState(/** @type ActivityWithMetadata[] */ ([]));

  useEffect(() => {
    activitiesPromise?.then((a) => {
      setActivities(a);
    });
  });

  const newerLabel = "← Newer";
  const olderLabel = "Older →";

  return (
    <>
      <div>
        {activities.map(({ activity, title, timestamp, detailUrl }) => (
          <ActivityListItem
            activity={activity}
            title={title}
            timestamp={timestamp}
            detailUrl={detailUrl}
          />
        ))}
        {!activities.length && (
          <>
            <ActivityPlaceholder />
            <ActivityPlaceholder />
            <ActivityPlaceholder />
          </>
        )}
      </div>
      <div className="clearfix">
        <div className="pagination-prev">
          {newerPage ? <Link to={{ search: newerPage }}>{newerLabel}</Link> : newerLabel}
        </div>
        <div className="pagination-next">
          {olderPage ? <Link to={{ search: olderPage }}>{olderLabel}</Link> : olderLabel}
        </div>
      </div>
    </>
  );
}

/**
 * @typedef ActivityListSourceProps
 * @property {import('react').ReactElement} children
 * @property {import('history').Location} location
 */

/**
 * @param {ActivityListSourceProps} props
 * @return {JSX.Element}
 */
ActivityList.Strava = ({ children, location }) => {
  const { cookies } = useContext(CookieContext);

  const pageParam = new URLSearchParams(location.search).get("page");
  const page = pageParam ? parseInt(pageParam, 10) : 1;

  // TODO: figure out maybe how to use state/memo/etc
  const activitiesPromise = Strava.getLoggedInAthleteActivities({
    accessToken: cookies.stravaAccessToken,
    fetch,
    page,
    perPage: 10,
  }).then(({ activities }) =>
    activities.map((activity) => ({
      detailUrl: ENDPOINTS.STRAVA.DETAIL({ activityId: String(activity.id) }),
      activity: Strava.toActivity(activity),
      title: activity.name,
      timestamp: activity.start_date ? new Date(activity.start_date) : undefined,
    }))
  );

  const newerPage = page > 1 ? `?page=${page - 1}` : null;
  const olderPage = `?page=${page + 1}`;

  return (
    <ActivityListContext.Provider
      value={{
        activitiesPromise,
        olderPage,
        newerPage,
      }}
    >
      {children}
    </ActivityListContext.Provider>
  );
};

export default ActivityList;
