/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from "react";
import { zoom, zoomTransform } from "d3-zoom";
import { select } from "d3-selection";
import { FullScreen, useWindowDimensionsEffectState } from "./fullscreen.jsx";
import { activityWithoutSharedSegments } from "../lib/activity.js";
import { MapStyles } from "../lib/map-style.js";
import { Map, projectAndGetBounds } from "../lib/map.jsx";
import Timestamp from "./timestamp.jsx";

const d3 = { zoom, zoomTransform, select };

/**
 * @typedef {import('../lib/activity.js').Activity} Activity
 */

/**
 * @typedef MapWithControlsProps
 * @property {Activity[]=} activities
 * @property {import('geojson').FeatureCollection=} mapboxFeatures
 * @property {number} height
 * @property {number} width
 * @property {import('../lib/map.jsx').BoundingBox} bbox
 * @property {boolean=} disableSharedSegmentsToggle
 * @property {import('../lib/map.jsx').BoundingBox[]=} debugRects
 * @property {number=} scale
 * @property {import('d3-geo').GeoProjection=} projection
 * @property {import('../lib/map-style').MapStyle=} mapStyle
 */

/**
 * @param {MapWithControlsProps} props
 * @return {JSX.Element}
 */
function MapWithControls({
  activities: inActivities,
  mapboxFeatures,
  height: inHeight,
  width: inWidth,
  bbox: inBbox,
  disableSharedSegmentsToggle,
  debugRects,
  scale: inScale,
  projection: inProjection,
  mapStyle: inMapStyle = MapStyles.DEFAULT,
}) {
  const defaultTransform = { x: 0, y: 0, scale: inScale };

  const [mapStyle, setMapStyle] = useState(inMapStyle);
  const [fullScreenEnabled, setFullscreenEnabled] = useState(false);
  const [windowDimensions] = useWindowDimensionsEffectState();
  const [showSharedSegments, setShowSharedSegments] = useState(true);
  const [showMarkers, setShowMarkers] = useState(true);
  const [hidden, setHidden] = useState(/** @type Activity[] */ ([]));
  const [transform, setTransform] = useState(defaultTransform);

  const ref = useRef(null);

  let filteredActivities = inActivities?.filter((a) => !hidden.includes(a)) || [];

  if (!showSharedSegments) {
    filteredActivities = filteredActivities.map((a) => activityWithoutSharedSegments(a));
  }
  if (!showMarkers) {
    filteredActivities = filteredActivities?.map((a) => ({
      ...a,
      markers: [],
    }));
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function changeColor(event) {
    const elem = /** @type {HTMLSelectElement?} */ (event.target);
    if (elem) {
      switch (elem.value) {
        case "black-and-white":
          setMapStyle(MapStyles.BLACK_AND_WHITE);
          return;
        case "no-terrain":
          setMapStyle(MapStyles.NO_TERRAIN);
          return;
        case "default":
        default:
          setMapStyle(MapStyles.DEFAULT);
      }
    }
  }

  let projection = inProjection;
  let bbox = inBbox;

  const width = fullScreenEnabled ? windowDimensions.width : inWidth;
  const height = fullScreenEnabled ? windowDimensions.height : inHeight;

  if (
    !projection ||
    !bbox ||
    filteredActivities.length !== inActivities?.length ||
    fullScreenEnabled
  ) {
    [projection, bbox] = projectAndGetBounds({
      width,
      height,
      activities: filteredActivities,
    });
  }

  useEffect(() => {
    const zoomBehavior = d3
      .zoom()
      .scaleExtent([0.5, 8])
      .on("zoom", (event) => {
        setTransform({
          x: event?.transform?.x || 0,
          y: event?.transform?.y || 0,
          scale: event?.transform?.k || 1,
        });
      });

    const selection = d3.select(ref.current).select("svg");

    if (fullScreenEnabled) {
      selection.call(/** @type any */ (zoomBehavior));
    }

    return () => {
      selection.on(".zoom", null);
    };
  }, [ref.current, fullScreenEnabled]);

  return (
    <>
      <FullScreen
        enabled={fullScreenEnabled}
        didClose={() => {
          setFullscreenEnabled(false);
          setTransform(defaultTransform);
        }}
      >
        <div ref={ref} onClick={() => setFullscreenEnabled(true)}>
          <Map
            activities={filteredActivities}
            mapboxFeatures={mapboxFeatures}
            mapStyle={mapStyle}
            bbox={bbox}
            width={width}
            height={height}
            debugRects={debugRects}
            scale={transform.scale}
            translate={[transform.x, transform.y]}
            projection={projection}
          />
        </div>
      </FullScreen>
      <form>
        <div>
          <button
            type="button"
            className="big-button"
            onClick={(e) => {
              e.preventDefault();
              setFullscreenEnabled(true);
            }}
          >
            Fullscreen
          </button>
        </div>
        <div>
          <label htmlFor="color-picker" className="big-label">
            Color Scheme
          </label>
          <select id="color-picker" className="big-select" onChange={changeColor}>
            <option value="default" selected={mapStyle === MapStyles.DEFAULT}>
              Default
            </option>
            <option value="black-and-white" selected={mapStyle === MapStyles.BLACK_AND_WHITE}>
              Black &amp; White
            </option>
            <option value="no-terrain" selected={mapStyle === MapStyles.NO_TERRAIN}>
              No Terrain
            </option>
          </select>
        </div>
        {!disableSharedSegmentsToggle && (
          <div>
            <div className="big-checkbox">
              <input
                type="checkbox"
                id="strava-segments"
                checked={showSharedSegments}
                onChange={(e) => setShowSharedSegments(e.target.checked)}
              />
              <label htmlFor="strava-segments">Strava Segments</label>
            </div>
          </div>
        )}
        <div>
          <div className="big-checkbox">
            <input
              type="checkbox"
              id="show-markers"
              checked={showMarkers}
              onChange={(e) => setShowMarkers(e.target.checked)}
            />
            <label htmlFor="show-markers">Show Start/Stop Markers</label>
          </div>
        </div>
        {inActivities?.length && inActivities?.length > 1 && (
          <details>
            <summary>Select Activities</summary>
            {inActivities.map((a, idx) => {
              const checkboxId = `activity-${idx}`;
              return (
                <div className="big-checkbox">
                  <input
                    type="checkbox"
                    id={checkboxId}
                    checked={!hidden.includes(a)}
                    onChange={() =>
                      hidden.includes(a)
                        ? setHidden(hidden.filter((x) => x !== a))
                        : setHidden(hidden.concat([a]))
                    }
                  />
                  <label htmlFor={checkboxId}>
                    {a.title || `Activity ${idx + 1}`}{" "}
                    {a.startTime && (
                      <small>
                        <Timestamp timestamp={new Date(a.startTime)} />
                      </small>
                    )}
                  </label>
                </div>
              );
            })}
          </details>
        )}
      </form>
    </>
  );
}

export default MapWithControls;
