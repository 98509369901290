import React, { useMemo } from "react";
import { geoPath, geoAlbers, geoBounds, geoCentroid } from "d3-geo";
import { toCoordinate } from "./activity.js";
import MapboxAttribution from "./mapbox-attribution.jsx";
import SportMapperAttribution from "./sport-mapper.jsx";
import { MapStyles, featureProperties } from "./map-style.js";

const d3 = { geoPath, geoAlbers, geoCentroid };

/**
 * @param {import('./activity.js').Coordinate} coordinate
 * @return {import('geojson').Point}
 */
function point([long, lat]) {
  return {
    type: "Point",
    coordinates: [long, lat],
  };
}

/**
 * @param {import('./activity.js').Coordinate[]} coordinates
 * @return {import('geojson').LineString}
 */
function lineString(coordinates) {
  return {
    type: "LineString",
    coordinates: coordinates.map(([long, lat]) => [long, lat]),
  };
}

/**
 * @param {import('./activity.js').Coordinate[][]} parts
 * @return {import('geojson').MultiLineString}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function multiLineString(parts) {
  return {
    type: "MultiLineString",
    coordinates: parts.map((coordinates) => coordinates.map(([long, lat]) => [long, lat])),
  };
}

/**
 * @param {import('geojson').Geometry[]} geometries
 * @return {import('geojson').GeometryCollection}
 */
function geometryCollection(geometries) {
  return {
    type: "GeometryCollection",
    geometries,
  };
}

/**
 * @typedef BoundingBox
 * @property {number} south
 * @property {number} west
 * @property {number} north
 * @property {number} east
 */

/**
 * @param {import('./activity.js').Activity[]} activities
 * @return {BoundingBox}
 */
function boundingBox(activities) {
  const geojson = geometryCollection(
    activities.flatMap(({ segments }) => segments.map((s) => lineString(s.coordinates)))
  );
  const [[left, bottom], [right, top]] = geoBounds(geojson);

  return {
    south: bottom,
    west: left,
    north: top,
    east: right,
  };
}

/**
 * @param {BoundingBox} bbox
 * @return {import('geojson').Geometry}
 */
function boundingBoxLine({ north, south, east, west }) {
  return lineString([
    toCoordinate({ long: west, lat: north }),
    toCoordinate({ long: east, lat: north }),
    toCoordinate({ long: east, lat: south }),
    toCoordinate({ long: west, lat: south }),
    toCoordinate({ long: west, lat: north }),
  ]);
}

/**
 * @param  {number} factor
 * @param  {BoundingBox} bbox
 * @return {BoundingBox}
 */
function scaleBoundingBox(factor, { north, south, east, west }) {
  const verticalMidpoint = (north + south) / 2;
  const horizontalMidpoint = (east + west) / 2;
  const height = north - south;
  const width = east - west;

  return {
    north: verticalMidpoint + factor * height * 0.5,
    south: verticalMidpoint - factor * height * 0.5,
    east: horizontalMidpoint + factor * width * 0.5,
    west: horizontalMidpoint - factor * width * 0.5,
  };
}

/**
 * @typedef ProjectionProps
 * @property {number} width
 * @property {number} height
 * @property {number=} padding
 * @property {import('geojson').Geometry} extentObj
 */

/**
 * @param {ProjectionProps} props
 * @return {import('d3-geo').GeoProjection}
 */
function buildProjection({ width, height, padding = 10, extentObj }) {
  let projection = d3.geoAlbers();

  const centroid = d3.geoCentroid(extentObj);
  if (centroid && centroid.length) {
    projection = projection.rotate([-centroid[0], 0, 0]);
  }

  return projection.fitExtent(
    [
      [padding, padding],
      [width - padding, height - padding],
    ],
    extentObj
  );
}

/**
 * @typedef SvgProps
 * @property {import('./activity.js').Activity[]=} activities
 * @property {import('geojson').FeatureCollection=} mapboxFeatures
 * @property {number=} width
 * @property {number=} height
 * @property {number=} scale
 * @property {[number,number]=} translate
 * @property {BoundingBox=} bbox
 * @property {BoundingBox[]=} debugRects
 * @property {import('./map-style').MapStyle=} mapStyle
 * @property {import('d3-geo').GeoProjection=} projection
 */

/**
 * @param {SvgProps} options
 * @return {import('react').ReactElement}
 */
function Map({
  activities,
  mapboxFeatures,
  bbox,
  debugRects,
  mapStyle: inMapStyle,
  width: inWidth,
  height: inHeight,
  scale: inScale,
  translate: inTranslate,
  projection: inProjection,
}) {
  const scale = inScale || 1;
  const [translateX, translateY] = inTranslate || [0, 0];
  const width = inWidth || 600;
  const height = inHeight || 400;
  const padding = 10;

  const mapStyle = inMapStyle || MapStyles.DEFAULT;
  const { terrainStyle, sportStyle, logoColor, textColor } = mapStyle;

  const segments = (activities || []).flatMap((a) => a.segments);
  const markers = (activities || []).flatMap((a) => a.markers);

  /** @type {import('geojson').Geometry} */
  let extentObj = {
    type: "Point",
    coordinates: [0, 0],
  };

  if (segments.length) {
    extentObj = geometryCollection(segments.map((s) => lineString(s.coordinates)));
  }

  if (bbox) {
    extentObj = boundingBoxLine(bbox);
  }

  const projection = inProjection || buildProjection({ width, height, padding, extentObj });

  /** @type {import('geojson').Geometry[]} */
  const debugLines = [];
  if (projection.invert) {
    const points = [
      projection.invert([0, 0]),
      projection.invert([width, 0]),
      projection.invert([width, height]),
      projection.invert([0, height]),
      projection.invert([0, 0]),
    ];
    /** @type {import('./activity.js').Coordinate[]} */
    const coordinates = [];
    points.forEach((p) => {
      if (p && p.length >= 2) {
        const [long, lat] = p;
        coordinates.push(toCoordinate({ long, lat }));
      }
    });

    debugLines.push(lineString(coordinates));
  }

  const path = d3
    .geoPath()
    .projection(projection)
    .pointRadius(2 / scale);

  const transform = `translate(${translateX || ((1 - scale) / 2) * width},${
    translateY || ((1 - scale) / 2) * height
  }) scale(${scale})`;
  const dashscale = 2 / scale;
  const dasharray = [dashscale, dashscale].join(",");

  const lineOpacity = 1 / (activities?.length || 1);

  const terrain = useMemo(
    () =>
      (mapboxFeatures?.features || [])
        .filter((g) => !terrainStyle.skip || !terrainStyle.skip(g))
        .sort((a, b) => {
          const aProps = featureProperties(a);
          const bProps = featureProperties(b);

          // water to front
          if (aProps.layer === "water" || aProps.layer === "waterway") {
            return -1;
          }
          if (bProps.layer === "water" || bProps.layer === "waterway") {
            return 1;
          }

          // structures to back
          if (aProps.layer === "structure") {
            return 1;
          }
          if (bProps.layer === "structure") {
            return -1;
          }

          return 0;
        })
        .map((feature) => {
          const { name, type, layer, structure } = featureProperties(feature);

          return {
            name: String(name),
            type: String(type),
            layer: String(layer),
            structure: String(structure),
            fill: terrainStyle.fillColor(feature),
            stroke: terrainStyle.strokeColor(feature),
            d: String(path(feature)),
          };
        }),
    [mapboxFeatures, terrainStyle, projection.scale(), ...projection.translate()]
  );

  const activitySegments = useMemo(
    () =>
      segments.map(({ coordinates, type }) => ({
        stroke: sportStyle.strokeColor(type),
        type,
        d: String(path(lineString(coordinates))),
      })),
    [activities, sportStyle, projection.scale(), ...projection.translate()]
  );

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={[0, 0, width, height].join(" ")}
      className="map"
    >
      <rect
        name="background"
        x="0"
        y="0"
        width={width}
        height={height}
        fill={terrainStyle.backgroundColor}
      />
      <g transform={transform} data-role="pan-and-zoom">
        <g className="terrain">
          {terrain.map(({ name, type, layer, structure, fill, stroke, d }) => (
            <g data-name={name} data-type={type} data-layer={layer} data-structure={structure}>
              <path
                fillRule="evenodd"
                fill={fill}
                stroke={stroke}
                strokeWidth={1 / scale / 2}
                d={d}
              />
            </g>
          ))}
        </g>
        <g className="segments">
          {activitySegments.map(({ stroke, type, d }) => (
            <path
              stroke={stroke}
              strokeWidth={1 / scale}
              strokeDasharray={type === "active" ? "" : dasharray}
              opacity={lineOpacity}
              fill="none"
              d={d}
            />
          ))}
        </g>
        <g className="markers">
          {markers.map((marker) => (
            <path
              stroke="none"
              fill={sportStyle.pointColor(marker.type)}
              opacity="1"
              d={String(path(point(marker.coordinate)))}
            />
          ))}
        </g>
        <g className="debug-rects">
          {false &&
            [...debugLines, ...(debugRects || []).map(boundingBoxLine)].map((line) => (
              <path fill="none" stroke="red" strokeWidth={1 / scale} d={String(path(line))} />
            ))}
        </g>
      </g>
      {mapboxFeatures && (
        <MapboxAttribution
          width={width}
          height={height}
          logoColor={logoColor}
          textColor={textColor}
          padding={5}
          fontSize={10}
        />
      )}
      <SportMapperAttribution
        width={width}
        height={height}
        logoColor={logoColor}
        padding={padding / 2}
        logotype={!!mapboxFeatures}
        fontSize={20}
      />
    </svg>
  );
}

/**
 * @typedef ProjectionBoundsProps
 * @property {number} width
 * @property {number} height
 * @property {number=} padding
 * @property {import('./activity.js').Activity[]} activities
 */

/**
 * @param {ProjectionBoundsProps} props
 * @return {[import('d3-geo').GeoProjection, BoundingBox]}
 */
function projectAndGetBounds({ width, height, padding = 25, activities }) {
  let bbox = boundingBox(activities);
  const extentObj = boundingBoxLine(bbox);

  const projection = buildProjection({ width, height, padding, extentObj });

  if (projection.invert) {
    const [topLeftLong, topLeftLat] = projection.invert([0, 0]) || [0, 0];
    const [topRightLong, topRightLat] = projection.invert([width, 0]) || [0, 0];
    const [bottomRightLong, bottomRightLat] = projection.invert([width, height]) || [0, 0];
    const [bottomLeftLong, bottomLeftLat] = projection.invert([0, height]) || [0, 0];

    bbox = {
      west: Math.min(topLeftLong, topRightLong, bottomRightLong, bottomLeftLong),
      east: Math.max(topLeftLong, topRightLong, bottomRightLong, bottomLeftLong),
      north: Math.max(topLeftLat, topRightLat, bottomRightLat, bottomLeftLat),
      south: Math.min(topLeftLat, topRightLat, bottomRightLat, bottomLeftLat),
    };
  }

  return [projection, bbox];
}

export { Map, boundingBox, scaleBoundingBox, boundingBoxLine, projectAndGetBounds };
