import React, { createContext } from "react";
import { getCookie } from "./cookie.js";

const CookieContext = createContext({
  cookies: /** @type Record<string,string> */ ({}),
});

/**
 * @typedef WithCookiesProps
 * @property {string[]} cookieNames
 * @property {import('react').ReactElement} children
 */

/**
 * @param {WithCookiesProps} props
 */
function WithCookies({ cookieNames, children }) {
  /** @type Record<string,string> */
  const cookies = {};
  let hasAll = true;

  cookieNames.forEach((cookieName) => {
    const value = getCookie(cookieName);
    if (value) {
      cookies[cookieName] = value;
    } else {
      hasAll = false;
    }
  });

  return hasAll ? (
    <CookieContext.Provider value={{ cookies }}>{children}</CookieContext.Provider>
  ) : (
    <></>
  );
}

export { WithCookies, CookieContext };
