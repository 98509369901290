import { scaleOrdinal } from "d3-scale";

const d3 = { scaleOrdinal };

/**
 * @typedef FeatureProperties
 * @property {import('geojson').GeoJsonTypes} shape
 * @property {string=} type
 * @property {string=} name
 * @property {string=} layer
 * @property {string=} structure
 */

/**
 * @param {import('geojson').GeoJSON} geometry
 * @return {FeatureProperties}
 */
function featureProperties(geometry) {
  const shape = geometry.type === "Feature" ? geometry.geometry.type : geometry.type;

  const properties = "properties" in geometry ? geometry.properties : {};

  return {
    shape,
    ...properties,
  };
}

/**
 * @typedef {import('geojson').GeoJSON} GeoJSON
 * @typedef {import('./activity.js').MarkerType} MarkerType
 * @typedef {import('./activity.js').SegmentType} SegmentType
 */

/**
 * @typedef SportStyle
 * @property {(type:SegmentType)=>string} strokeColor
 * @property {(type:MarkerType)=>string} pointColor
 */

/**
 * @typedef TerrainStyle
 * @property {(object:GeoJSON)=>string} fillColor
 * @property {(object:GeoJSON)=>string} strokeColor
 * @property {(object:GeoJSON)=>boolean=} skip
 * @property {string} backgroundColor
 */

/**
 * @typedef MapStyle
 * @property {TerrainStyle} terrainStyle
 * @property {SportStyle} sportStyle;
 * @property {string} logoColor
 * @property {string} textColor
 */

/**
 * @param {GeoJSON} geometry
 * @return {boolean}
 */
const DEFAULT_SKIP = (geometry) => {
  const { type, layer } = featureProperties(geometry);

  if (
    layer?.endsWith("_label") ||
    type?.startsWith("ferry") ||
    type === "lightrail" ||
    type === "subway" ||
    layer === "landuse" ||
    layer === "landuse_overlay" ||
    layer === "building" ||
    layer === "motorway_junction"
  ) {
    return true;
  }

  return false;
};

/**
 * @type {TerrainStyle}
 */
const COLOR = {
  backgroundColor: "beige",
  strokeColor: (geometry) => {
    const { shape } = featureProperties(geometry);

    if (shape !== "LineString" && shape !== "MultiLineString") {
      return "none";
    }

    return "lightgray";
  },

  skip: DEFAULT_SKIP,

  fillColor: (geometry) => {
    const { type, layer, shape } = featureProperties(geometry);

    if (shape === "LineString" || shape === "MultiLineString") {
      return "none";
    }

    switch (layer) {
      case "structure":
        if (type === "pier" || type === "breakwater") {
          return "lightgray";
        }
        return "none";
      case "water":
      case "waterway":
        return "lightcyan";
      default:
    }

    return "none";
  },
};

/**
 * @type {TerrainStyle}
 */
const BLACK_AND_WHITE = {
  backgroundColor: "black",
  strokeColor: (geometry) => {
    const { shape } = featureProperties(geometry);

    if (shape !== "LineString" && shape !== "MultiLineString") {
      return "none";
    }

    return "black";
  },
  skip: (geometry) => {
    const { type, layer } = featureProperties(geometry);

    if (
      String(layer).endsWith("_label") ||
      type === "ferry" ||
      type === "lightrail" ||
      type === "subway" ||
      layer === "landuse" ||
      layer === "landuse_overlay" ||
      layer === "building" ||
      layer === "motorway_junction"
    ) {
      return true;
    }

    return false;
  },
  fillColor: (geometry) => {
    const { type, layer, shape } = featureProperties(geometry);

    if (shape === "LineString" || shape === "MultiLineString") {
      return "none";
    }

    if (layer === "structure") {
      if (type === "pier" || type === "breakwater") {
        return "black";
      }
      return "none";
    }

    if (layer === "water" || layer === "waterway") {
      return "white";
    }

    return "none";
  },
};

const NONE = {
  backgroundColor: "white",
  fillColor: () => "none",
  logoColor: "black",
  strokeColor: () => "none",
  textColor: "text",
};

const TerrainStyles = {
  DEFAULT: COLOR,
  BLACK_AND_WHITE,
  NONE,
};

const SportStyles = {
  DEFAULT: {
    strokeColor: d3
      .scaleOrdinal()
      .domain(["active", "filler", "shared"])
      .range(["black", "gray", "steelblue"]),
    pointColor: d3
      .scaleOrdinal()
      .domain(["start", "stop", "lap", "shared_start", "shared_stop"])
      .range(["green", "tomato", "steelblue", "lightsteelblue", "steelblue"]),
  },
  RED: {
    strokeColor: d3
      .scaleOrdinal()
      .domain(["active", "filler", "shared"])
      .range(["red", "tomato", "steelblue"]),
    pointColor: d3
      .scaleOrdinal()
      .domain(["start", "stop", "lap", "shared_start", "shared_stop"])
      .range(["green", "red", "tomato", "none", "none"]),
  },
};

const MapStyles = {
  DEFAULT: {
    terrainStyle: COLOR,
    sportStyle: SportStyles.DEFAULT,
    logoColor: "black",
    textColor: "black",
  },
  BLACK_AND_WHITE: {
    terrainStyle: BLACK_AND_WHITE,
    sportStyle: SportStyles.RED,
    logoColor: "darkgray",
    textColor: "darkgray",
  },
  NO_TERRAIN: {
    sportStyle: SportStyles.DEFAULT,
    terrainStyle: TerrainStyles.NONE,
    logoColor: "darkgray",
    textColor: "darkgray",
  },
};

export { MapStyles, featureProperties };
