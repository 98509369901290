/**
 * @template T
 * @param {T[]} arr
 * @return {T}
 */
function last(arr) {
  return arr[arr.length - 1];
}

/**
 * Define the browser File since tsc runs in node
 * @typedef File
 * @property {()=>Promise<string>} text
 * @property {()=>Promise<ArrayBuffer>} arrayBuffer
 */

/**
 * @typedef {ArrayBuffer|File} BufferLike
 */

/**
 * @typedef {ArrayBuffer|File|string} BufferLikeOrString
 */

/**
 * @param {BufferLikeOrString} input
 * @return {Promise<string>}
 */
function text(input) {
  if (typeof File !== "undefined" && input instanceof File) {
    return input.text();
  }
  if (input instanceof ArrayBuffer) {
    return new Promise((r) => r(String.fromCharCode.apply(null, new Uint8Array(input))));
  }
  return new Promise((r) => r(input.toString()));
}

export { last, text };
