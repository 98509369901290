import React, { useContext, createContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ENDPOINTS } from "../lib/sportmap.club.js";
import { CookieContext } from "./cookie-context.jsx";
import * as Strava from "../lib/strava.js";
import { mapboxTiles } from "./mapbox.js";
import { projectAndGetBounds } from "../lib/map.jsx";
import MapWithControls from "./map-with-controls.jsx";
import Chart from "./chart.jsx";
import { activityHasTimestamps } from "../lib/activity.js";
/**
 * @typedef {import('../lib/activity.js').Activity} Activity
 */

const ActivityDetailContext = createContext({
  activityPromise: /** @type Promise<Activity>? */ (null),
  projectionBboxPromise: /** @type Promise<[import('d3-geo').GeoProjection, import('../lib/map.js').BoundingBox]>? */ (null),
  mapboxFeaturesPromise: /** @type Promise<import('geojson').FeatureCollection>? */ (null),
  activityWithStravaSegmentsPromise: /** @type Promise<Activity>? */ (null),
  listPage: "/",
});

/**
 * @typedef ActivityListProps
 * @property {number} width
 * @property {number} height
 */

/**
 * @param {ActivityListProps} props
 * @return {JSX.Element}
 */
function ActivityDetail({ width, height }) {
  const {
    activityPromise,
    projectionBboxPromise,
    mapboxFeaturesPromise,
    activityWithStravaSegmentsPromise,
    listPage,
  } = useContext(ActivityDetailContext);

  const [activity, setActivity] = useState(/** @type Activity? */ (null));
  const [projectionBbox, setProjectionBbox] = useState(
    /** @type [import('d3-geo').GeoProjection, import('../lib/map.js').BoundingBox]? */ (null)
  );
  const [mapboxFeatures, setMapboxFeatures] = useState(
    /** @type import('geojson').FeatureCollection? */ (null)
  );
  const [activityWithStravaSegments, setActivityWithStravaSegments] = useState(
    /** @type Activity? */ (null)
  );

  const [projection, bbox] = projectionBbox || [];

  useEffect(() => {
    activityPromise?.then((a) => setActivity(a));
    projectionBboxPromise?.then((p) => setProjectionBbox(p));
    mapboxFeaturesPromise?.then((m) => setMapboxFeatures(m));
    activityWithStravaSegmentsPromise?.then((a) => setActivityWithStravaSegments(a));
  });

  return (
    <>
      <Link to={listPage} className="nav-pill">
        « Back to list
      </Link>
      {activity && projection && bbox ? (
        <MapWithControls
          activities={activityWithStravaSegments ? [activityWithStravaSegments] : [activity]}
          mapboxFeatures={mapboxFeatures ?? undefined}
          bbox={bbox}
          width={width}
          height={height}
          projection={projection}
        />
      ) : undefined}
      {activity && activityHasTimestamps(activity) && <Chart activity={activity} width={width} />}
    </>
  );
}

/**
 * @typedef ActivityDetailStravaProps
 * @property {number} width
 * @property {number} height
 * @property {import('react').ReactElement} children
 */

/**
 * @param {ActivityDetailStravaProps} props
 * @return {JSX.Element}
 */
ActivityDetail.Strava = ({ children, width, height }) => {
  const { activityId } = /** @type any */ (useParams());
  const {
    cookies: { stravaAccessToken: accessToken },
  } = useContext(CookieContext);

  const stravaPromise = Strava.getActivity({
    fetch,
    activityId,
    accessToken,
  });

  const streamsPromise = Strava.getActivityStreams({ fetch, activityId, accessToken });

  const activityPromise = Promise.all([stravaPromise, streamsPromise]).then(
    ([activity, streams]) => {
      let distanceStream;
      let timeStream;
      let latLngStream;

      // switch/case needed for discriminated union type
      streams.forEach((stream) => {
        switch (stream.type) {
          case "distance":
            distanceStream = stream;
            break;
          case "latlng":
            latLngStream = stream;
            break;
          case "time":
            timeStream = stream;
            break;
          default:
        }
      });

      return Strava.toActivity(activity, { distanceStream, timeStream, latLngStream });
    }
  );

  const activityWithStravaSegmentsPromise = stravaPromise.then((activity) => {
    const segmentIds = Array.from(
      new Set(activity.segment_efforts.map(({ segment }) => segment.id))
    );

    const segmentPromises = segmentIds.map((segmentId) =>
      Strava.getSegment({
        segmentId,
        accessToken: String(accessToken),
        fetch,
      })
    );

    return Promise.all(segmentPromises).then((segments) =>
      Strava.toActivity(activity, { stravaSegments: segments })
    );
  });

  const projectionBboxPromise = activityPromise.then((activity) =>
    projectAndGetBounds({
      width,
      height,
      activities: [activity],
    })
  );

  const mapboxFeaturesPromise = projectionBboxPromise.then(([, bbox]) => mapboxTiles({ bbox }));

  return (
    <ActivityDetailContext.Provider
      value={{
        activityPromise,
        mapboxFeaturesPromise,
        activityWithStravaSegmentsPromise,
        projectionBboxPromise,
        listPage: ENDPOINTS.STRAVA.LIST,
      }}
    >
      {children}
    </ActivityDetailContext.Provider>
  );
};

export default ActivityDetail;
