import OAuthSignature from "oauth-signature";
import { buildNonce } from "./random.js";

/**
 * @typedef {import('./fetch.js').WithFetch} WithFetch
 * @typedef {import('./fetch.js').Fetch} Fetch
 */

/**
 * @typedef OAuthHeaderProps
 * @property {string} method
 * @property {string} url
 * @property {import('oauth-signature').OAuthParameters} parameters
 * @property {string} oauthConsumerSecret
 * @property {string=} requestTokenSecret
 */

/**
 * @param {OAuthHeaderProps} props
 * @returns {string}
 */
function oauthHeader({ method, url, parameters, oauthConsumerSecret, requestTokenSecret }) {
  // Decode it because it get re-encoded below
  const oauthSignature = decodeURIComponent(
    OAuthSignature.generate(method, url, parameters, oauthConsumerSecret, requestTokenSecret)
  );

  /** @type {string[]} */
  const headerParts = [];
  Object.entries({ oauth_signature: oauthSignature, ...parameters }).forEach(([key, value]) => {
    if (value !== undefined) {
      headerParts.push(`${key}="${encodeURIComponent(value)}"`);
    }
  });

  return `OAuth ${headerParts.join(", ")}`;
}

/**
 * @param {Fetch} fetch
 * @param {import('oauth-signature').OAuthParameters} oauthParams
 * @param {string} oauthConsumerSecret
 * @param {string=} requestTokenSecret
 * @return {Fetch}
 */
function fetchWithOAuth(fetch, oauthParams, oauthConsumerSecret, requestTokenSecret) {
  return (inUrl, inOptions) => {
    const parameters = { ...oauthParams };

    const noParamsUrl = new URL(inUrl);
    noParamsUrl.searchParams.forEach((value, key) => {
      parameters[key] = value;
    });
    noParamsUrl.search = "";

    const options = { ...inOptions };
    if (!options.headers) {
      options.headers = {};
    }

    options.headers.Authorization = oauthHeader({
      method: options.method,
      url: noParamsUrl.toString(),
      parameters,
      oauthConsumerSecret,
      requestTokenSecret,
    });

    // console.debug(noParamsUrl.toString());
    // console.debug(options.method);
    // console.debug(parameters);
    // console.debug(options.headers.Authorization);

    return fetch(inUrl, options);
  };
}

/**
 * @typedef OAuthTokenResponse
 * @property {string} oauthToken
 * @property {string} oauthTokenSecret
 */

/**
 * @param {Response} response
 * @return {Promise<OAuthTokenResponse>}
 */
function parseOAuthResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      throw new Error(`error status=${response.status} body=${text}`);
    }

    const parsed = new URLSearchParams(text);

    return {
      oauthToken: parsed.get("oauth_token") || "",
      oauthTokenSecret: parsed.get("oauth_token_secret") || "",
    };
  });
}

/**
 * Returns the number of whole seconds since the epoch as a string
 * @param {Date} date=
 * @returns {string}
 */
function toSeconds(date) {
  return Math.round(date.valueOf() / 1000).toString();
}

/**
 * @typedef OAuthRequestProps
 * @property {string} oauthConsumerKey
 * @property {string} oauthConsumerSecret
 * @property {string=} oauthNonce
 * @property {Date=} now
 */

/**
 * @typedef RequestTokenResponse
 * @property {string} requestToken
 * @property {string} requestTokenSecret
 */

/**
 * @param {OAuthRequestProps & WithFetch} params
 * @return {Promise<RequestTokenResponse>}
 */
function getRequestToken({
  fetch,
  oauthConsumerKey,
  oauthConsumerSecret,
  oauthNonce = buildNonce(),
  now = new Date(),
}) {
  const url = new URL("https://connectapi.garmin.com/oauth-service/oauth/request_token");

  const oauthParams = {
    oauth_consumer_key: oauthConsumerKey,
    oauth_signature_method: "HMAC-SHA1",
    oauth_nonce: oauthNonce,
    oauth_timestamp: toSeconds(now),
    oauth_version: "1.0",
  };

  const oauthFetch = fetchWithOAuth(fetch, oauthParams, oauthConsumerSecret);

  return oauthFetch(url.toString(), { method: "POST" }).then((response) =>
    parseOAuthResponse(response).then(({ oauthToken, oauthTokenSecret }) => ({
      requestToken: oauthToken,
      requestTokenSecret: oauthTokenSecret,
    }))
  );
}

/**
 * @typedef AccessTokenRequest
 * @property {string} requestToken
 * @property {string} requestTokenSecret
 * @property {string} oauthVerifier
 */

/**
 * @typedef AccessTokenResponse
 * @property {string} accessToken
 * @property {string} accessTokenSecret
 */

/**
 * @param {AccessTokenRequest & OAuthRequestProps & WithFetch} props
 * @return {Promise<AccessTokenResponse>}
 */
function getAccessToken({
  fetch,
  requestToken,
  requestTokenSecret,
  oauthVerifier,
  oauthConsumerSecret,
  oauthConsumerKey,
  oauthNonce = buildNonce(),
  now = new Date(),
}) {
  const url = new URL("https://connectapi.garmin.com/oauth-service/oauth/access_token");

  const oauthParams = {
    oauth_consumer_key: oauthConsumerKey,
    oauth_token: requestToken,
    oauth_signature_method: "HMAC-SHA1",
    oauth_nonce: oauthNonce,
    oauth_timestamp: toSeconds(now),
    oauth_version: "1.0",
    oauth_verifier: oauthVerifier,
  };

  const oauthFetch = fetchWithOAuth(fetch, oauthParams, oauthConsumerSecret, requestTokenSecret);

  return oauthFetch(url.toString(), { method: "POST" }).then((response) =>
    parseOAuthResponse(response).then(({ oauthToken, oauthTokenSecret }) => ({
      accessToken: oauthToken,
      accessTokenSecret: oauthTokenSecret,
    }))
  );
}

/**
 * @typedef AuthorizeParams
 * @property {string} requestToken
 * @property {string|URL=} oauthCallback
 */

/**
 * @param {AuthorizeParams} params
 * @return {URL}
 */
function authorizeUrl({ requestToken, oauthCallback }) {
  const url = new URL("https://connect.garmin.com/oauthConfirm");

  url.searchParams.set("oauth_token", requestToken);
  if (oauthCallback) {
    url.searchParams.set("oauth_callback", String(oauthCallback));
  }

  return url;
}

/**
 * @typedef GetActivitySummaryRequest
 * @property {Date} uploadStartTime
 * @property {Date} uploadEndTime
 */

/**
 * @param {GetActivitySummaryRequest & WithFetch} props
 */
function getActivitySummaries({ fetch, uploadStartTime, uploadEndTime }) {
  const url = new URL("https://apis.garmin.com/wellness-api/rest/activities");
  if (uploadStartTime && uploadEndTime) {
    url.searchParams.set("uploadStartTimeInSeconds", toSeconds(uploadStartTime));
    url.searchParams.set("uploadEndTimeInSeconds", toSeconds(uploadEndTime));
  }

  return fetch(url.toString(), { method: "GET" });
}

/**
 * @typedef WithAccessToken
 * @property {string} accessToken
 * @property {string} accessTokenSecret
 */

/**
 * @param {WithAccessToken & OAuthRequestProps & WithFetch} props
 */
function fetchWithAccessToken({
  fetch,
  accessToken,
  accessTokenSecret,
  oauthConsumerKey,
  oauthConsumerSecret,
  oauthNonce = buildNonce(),
  now = new Date(),
}) {
  const oauthParams = {
    oauth_consumer_key: oauthConsumerKey,
    oauth_token: accessToken,
    oauth_signature_method: "HMAC-SHA1",
    oauth_nonce: oauthNonce,
    oauth_timestamp: toSeconds(now),
    oauth_version: "1.0",
  };

  return fetchWithOAuth(fetch, oauthParams, oauthConsumerSecret, accessTokenSecret);
}

export {
  authorizeUrl,
  getRequestToken,
  getAccessToken,
  getActivitySummaries,
  fetchWithAccessToken,
};
