import { csvParse, autoType } from "d3-dsv";
import { toCoordinate } from "./activity.js";
import { duration } from "./time.js";
import { last, text } from "./util.js";

/**
 * @param {string} str
 * @return {Date=}
 */
function parseDate(str) {
  // "2021-09-08 12:44:08"
  const defaultFormat = new Date(str);
  if (+defaultFormat) {
    return defaultFormat;
  }

  const match = str.match(/(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/);
  if (match && match[1] && match[2]) {
    return new Date(`${match[1]}T${match[2]}Z`);
  }

  return undefined;
}

/**
 * @param {string} csv (CSV string)
 * @param {string=} filename
 * @return {import('./activity.js').Activity}
 */
function fromCsv(csv, filename) {
  const parsed = csvParse(csv, autoType);

  let longColName = "lon";
  let latColName = "lat";
  let timeColName = "time";

  parsed.columns.forEach((col) => {
    if (/lo?ng/i.test(col)) {
      longColName = col;
    } else if (/lat/i.test(col)) {
      latColName = col;
    } else if (/time/i.test(col)) {
      timeColName = col;
    }
  });

  const coordinates = parsed.map((/** @type any */ row) =>
    toCoordinate({
      long: row[longColName],
      lat: row[latColName],
      timestamp: row[timeColName] ? parseDate(row[timeColName]) : undefined,
    })
  );

  /** @type {import('./activity.js').Marker[]} */
  let markers = [];

  let totalDuration;
  let startTime;
  let endTime;

  if (coordinates.length) {
    const firstCoord = coordinates[0];
    const lastCoord = last(coordinates);
    markers = [
      { type: "start", coordinate: firstCoord },
      { type: "stop", coordinate: lastCoord },
    ];

    [, , startTime] = firstCoord;
    [, , endTime] = lastCoord;
    if (startTime && endTime) {
      totalDuration = duration({ startTime, endTime });
    }
  }

  return {
    markers,
    segments: [{ coordinates, type: "active" }],
    title: filename,
    startTime: startTime?.valueOf(),
    totalDuration,
    movingDuration: totalDuration,
  };
}

/**
 * @param {import('./util.js').BufferLikeOrString} input
 * @return {Promise<import('./activity.js').Activity>}
 */
function parseCsvToActivity(input) {
  const name = typeof File !== "undefined" && input instanceof File ? input.name : undefined;
  return text(input).then((str) => fromCsv(str, name));
}

export { parseCsvToActivity, fromCsv };
