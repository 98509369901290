import React from "react";
import { timeFormat } from "d3-time-format";

const d3 = { timeFormat };

const FORMAT = d3.timeFormat("%b %e, %Y %_I:%M %p");
const FORMAT_WEEKDAY = d3.timeFormat("%a %b %e, %Y %_I:%M %p");

/**
 * @typedef TimestampProps
 * @property {Date|string} timestamp
 * @property {boolean=} showWeekday
 */

/**
 * @param {TimestampProps} props
 * @return {import('react').ReactElement}
 */
function Timestamp({ timestamp, showWeekday }) {
  /** @type {Date?} */
  let date = null;

  if (typeof timestamp === "string") {
    date = new Date(timestamp);
  } else if (typeof timestamp === "object") {
    date = timestamp;
  }

  const formatter = showWeekday ? FORMAT_WEEKDAY : FORMAT;

  return <>{date && formatter(date)}</>;
}

export default Timestamp;
