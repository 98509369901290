import { last, text } from "./util.js";
import { toCoordinate } from "./activity.js";
import { duration } from "./time.js";

/**
 * @typedef {import('./activity.js').Coordinate} Coordinate
 */

/**
 * @template A
 * @param {Element} node
 * @param {string} tagName
 * @param {(value:string)=>A} transform
 * @return {A=}
 */
function nodeValue(node, tagName, transform) {
  const nodes = node.getElementsByTagName(tagName);
  if (nodes.length) {
    const value = nodes[0].textContent;
    if (value) {
      return transform(value);
    }
  }
  return undefined;
}

/**
 * @typedef TcxOptions
 * @property {boolean=} fillGaps whether or not to fill in gaps between segments
 * @property {import('xmldom').DOMParser=} domparser pass an instance of DOMParser so we can work in
 * the browser or node
 * @property {string=} filename
 */

/**
 * @param {TcxOptions & { xml: string }} options
 * @return {import('./activity.js').Activity}
 */
function fromTcx({ xml, fillGaps = true, domparser: inDomparser }) {
  let domparser = inDomparser;

  if (!domparser) {
    if (typeof window.DOMParser !== "undefined") {
      domparser = new window.DOMParser();
    } else {
      throw new Error("could not load DOMParser");
    }
  }

  const dom = domparser.parseFromString(xml, "text/xml");

  /** @type {import('./activity.js').Segment[]} */
  const segments = [];
  /** @type {import('./activity.js').Marker[]} */
  const markers = [];

  /** @type {number=} */
  let totalDuration;
  /** @type {number=} */
  let movingDuration;

  Array.from(dom.getElementsByTagName("Lap")).forEach((lap) => {
    /** @type {Coordinate[]} */
    const coordinates = [];

    const totalTimerSeconds = nodeValue(lap, "TotalTimeSeconds", parseFloat);
    if (totalTimerSeconds) {
      movingDuration = (movingDuration || 0) + totalTimerSeconds * 1000;
    }

    Array.from(lap.getElementsByTagName("Trackpoint")).forEach((trackpoint) => {
      const timestamp = nodeValue(trackpoint, "Time", (v) => new Date(v));
      const lat = nodeValue(trackpoint, "LatitudeDegrees", parseFloat);
      const long = nodeValue(trackpoint, "LongitudeDegrees", parseFloat);

      if (long && lat) {
        coordinates.push(toCoordinate({ long, lat, timestamp }));
      }
    });

    const firstCoordinate = coordinates[0];
    const lastCoordinate = last(coordinates);

    if (firstCoordinate !== undefined) {
      markers.push({ type: "start", coordinate: firstCoordinate });
    }

    if (lastCoordinate !== undefined) {
      markers.push({ type: "stop", coordinate: lastCoordinate });
    }

    const lastSegment = last(segments);
    if (firstCoordinate !== undefined) {
      if (lastSegment?.coordinates.length && fillGaps) {
        segments.push({
          coordinates: [last(lastSegment.coordinates), firstCoordinate],
          type: "filler",
        });
      }

      segments.push({ coordinates, type: "active" });
    }
  });

  let startTime;
  if (segments.length) {
    if (segments[0].coordinates.length) {
      [[, , startTime]] = segments[0].coordinates;
    }
    const lastSegment = last(segments);
    if (lastSegment && lastSegment.coordinates.length) {
      const [, , endTime] = last(lastSegment.coordinates);
      if (startTime && endTime) {
        totalDuration = duration({ startTime, endTime });
      }
    }
  }

  return {
    markers,
    segments,
    startTime: startTime?.valueOf(),
    totalDuration,
    movingDuration,
  };
}

/**
 * @param {import('./util.js').BufferLikeOrString} input
 * @param {TcxOptions=} options
 * @return {Promise<import('./activity.js').Activity>}
 */
function parseTcxToActivity(input, options = {}) {
  return text(input).then((str) => fromTcx({ xml: str, ...options }));
}

export { parseTcxToActivity, fromTcx };
