/**
 * @param {string} key
 * @param {string} value
 * @param {number} expiresIn
 * @return {string}
 */
function setCookie(key, value, expiresIn) {
  document.cookie = `${key}=${value};max-age=${expiresIn};path=/`;
  return value;
}

/**
 * @param {string} key
 * @return {string=}
 */
function getCookie(key) {
  const match = document.cookie.match(new RegExp(`${key}=([^;]+)`));
  if (match && match[1]) {
    return match[1];
  }
  return undefined;
}

export { setCookie, getCookie };
