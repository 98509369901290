import { vt2geojson } from "./vt2geojson.js";

const ACCESS_TOKEN = String(process.env.MAPBOX_ACCESS_TOKEN);

// From: https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29

/**
 * @param {number} lon
 * @param {number} zoom
 * @return {number}
 */
function lon2tile(lon, zoom) {
  return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
}

/**
 * @param {number} lat
 * @param {number} zoom
 * @return {number}
 */
function lat2tile(lat, zoom) {
  return Math.floor(
    ((1 -
      Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) /
      2) *
      Math.pow(2, zoom)
  );
}

/**
 * @param {number} width degrees
 * @return {number} zoom
 */
function widthToZoom(width) {
  // 360 / (2 ^ z) = width
  // log2(360 / width) = z

  return Math.ceil(Math.log2(360 / width));
}

/**
 * @typedef TileUrlProps
 * @property {number} x
 * @property {number} y
 * @property {number} zoom
 * @property {string} accessToken
 */

/**
 * @param {TileUrlProps} props
 * @return {URL}
 */
function tileUrl({ x, y, zoom, accessToken }) {
  const tileset = "mapbox.mapbox-streets-v8";

  const url = new URL(`https://api.mapbox.com/v4/${tileset}/${zoom}/${x}/${y}.mvt`);
  url.searchParams.set("access_token", accessToken);

  return url;
}

/**
 * @typedef {import('../lib/map.jsx').BoundingBox} BoundingBox
 */

/**
 * @typedef MapboxTilesProps
 * @property {BoundingBox} bbox
 * @property {number=} extraTiles
 */

/**
 * @param {MapboxTilesProps} props
 * @return {Promise<import('geojson').FeatureCollection>}
 */
function mapboxTiles({ bbox: { south, west, north, east }, extraTiles = 0 }) {
  const width = Math.max(east, west) - Math.min(east, west);
  const zoom = widthToZoom(width);

  const topTile = lat2tile(north, zoom);
  const leftTile = lon2tile(west, zoom);
  const bottomTile = lat2tile(south, zoom);
  const rightTile = lon2tile(east, zoom);

  /**
   * @type {Promise<import('geojson').FeatureCollection>[]}
   */
  const promises = [];

  for (
    let x = Math.min(leftTile, rightTile) - extraTiles;
    x <= Math.max(leftTile, rightTile) + extraTiles;
    ++x
  ) {
    for (
      let y = Math.min(bottomTile, topTile) - extraTiles;
      y <= Math.max(bottomTile, topTile) + extraTiles;
      ++y
    ) {
      promises.push(
        vt2geojson({
          url: tileUrl({
            x,
            y,
            zoom,
            accessToken: ACCESS_TOKEN,
          }),
          x,
          y,
          z: zoom,
        })
      );
    }
  }

  return Promise.all(promises).then((featureCollections) => ({
    type: "FeatureCollection",
    features: featureCollections.flatMap(({ features }) => features),
  }));
}

export { mapboxTiles, widthToZoom };
