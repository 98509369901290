import React from "react";

/**
 * @typedef LogoProps
 * @property {number=} width
 * @property {number=} height
 * @property {string} fill
 * @property {number=} y
 * @property {number=} x
 */

/**
 * Mapbox logo, from their press kit, adapted to replace `st0` class with a fill color, and scale
 * @param {LogoProps} props
 * @return {import('react').ReactElement}
 */
function MapboxLogo({ width: inWidth, height: inHeight, fill, x: inX, y: inY }) {
  // Adapted from viewBox="0 0 800 180"
  const originalWidth = 800;
  const originalHeight = 180;
  const widthToHeightRatio = originalWidth / originalHeight;

  const x = inX || 0;
  const y = inY || 0;
  let height = inHeight;
  let width = inWidth;

  if (!height && width) {
    height = width / widthToHeightRatio;
  } else if (height && !width) {
    width = height * widthToHeightRatio;
  }

  if (!height || !width) {
    height = originalHeight;
    width = originalWidth;
  }

  const transform = `
    translate(${x}, ${y - height})
    scale(${width / originalWidth}, ${height / originalHeight})
  `;

  return (
    <g transform={transform}>
      <title>Mapbox_Logo_08</title>
      <g>
        <g>
          <path
            fill={fill}
            d="M594.6,49.8c-9.9,0-19.4,4.1-26.3,11.3V23c0-1.2-1-2.2-2.2-2.2l0,0h-13.4c-1.2,0-2.2,1-2.2,2.2v103
          c0,1.2,1,2.2,2.2,2.2h13.4c1.2,0,2.2-1,2.2-2.2v0v-7.1c6.9,7.2,16.3,11.3,26.3,11.3c20.9,0,37.8-18,37.8-40.2
          S615.5,49.8,594.6,49.8z M591.5,114.1c-12.7,0-23-10.6-23.1-23.8v-0.6c0.2-13.2,10.4-23.8,23.1-23.8c12.8,0,23.1,10.8,23.1,24.1
          S604.2,114.1,591.5,114.1L591.5,114.1z"
          />
          <path
            fill={fill}
            d="M681.7,49.8c-22.6,0-40.9,18-40.9,40.2s18.3,40.2,40.9,40.2c22.6,0,40.9-18,40.9-40.2S704.3,49.8,681.7,49.8z
           M681.6,114.1c-12.8,0-23.1-10.8-23.1-24.1s10.4-24.1,23.1-24.1s23.1,10.8,23.1,24.1S694.3,114.1,681.6,114.1L681.6,114.1z"
          />
          <path
            fill={fill}
            d="M431.6,51.8h-13.4c-1.2,0-2.2,1-2.2,2.2c0,0,0,0,0,0v7.1c-6.9-7.2-16.3-11.3-26.3-11.3
          c-20.9,0-37.8,18-37.8,40.2s16.9,40.2,37.8,40.2c9.9,0,19.4-4.1,26.3-11.3v7.1c0,1.2,1,2.2,2.2,2.2l0,0h13.4c1.2,0,2.2-1,2.2-2.2
          v0V54C433.8,52.8,432.8,51.8,431.6,51.8z M392.8,114.1c-12.8,0-23.1-10.8-23.1-24.1s10.4-24.1,23.1-24.1c12.7,0,23,10.6,23.1,23.8
          v0.6C415.8,103.5,405.5,114.1,392.8,114.1L392.8,114.1z"
          />
          <path
            fill={fill}
            d="M498.5,49.8c-9.9,0-19.4,4.1-26.3,11.3V54c0-1.2-1-2.2-2.2-2.2l0,0h-13.4c-1.2,0-2.2,1-2.2,2.2c0,0,0,0,0,0
          v103c0,1.2,1,2.2,2.2,2.2l0,0h13.4c1.2,0,2.2-1,2.2-2.2v0v-38.1c6.9,7.2,16.3,11.3,26.3,11.3c20.9,0,37.8-18,37.8-40.2
          S519.4,49.8,498.5,49.8z M495.4,114.1c-12.7,0-23-10.6-23.1-23.8v-0.6c0.2-13.2,10.4-23.8,23.1-23.8c12.8,0,23.1,10.8,23.1,24.1
          S508.2,114.1,495.4,114.1L495.4,114.1z"
          />
          <path
            fill={fill}
            d="M311.8,49.8c-10,0.1-19.1,5.9-23.4,15c-4.9-9.3-14.7-15.1-25.2-15c-8.2,0-15.9,4-20.7,10.6V54
          c0-1.2-1-2.2-2.2-2.2l0,0h-13.4c-1.2,0-2.2,1-2.2,2.2c0,0,0,0,0,0v72c0,1.2,1,2.2,2.2,2.2h0h13.4c1.2,0,2.2-1,2.2-2.2v0V82.9
          c0.5-9.6,7.2-17.3,15.4-17.3c8.5,0,15.6,7.1,15.6,16.4v44c0,1.2,1,2.2,2.2,2.2l13.5,0c1.2,0,2.2-1,2.2-2.2c0,0,0,0,0,0l-0.1-44.8
          c1.2-8.8,7.5-15.6,15.2-15.6c8.5,0,15.6,7.1,15.6,16.4v44c0,1.2,1,2.2,2.2,2.2l13.5,0c1.2,0,2.2-1,2.2-2.2c0,0,0,0,0,0l-0.1-49.5
          C339.9,61.7,327.3,49.8,311.8,49.8z"
          />
          <path
            fill={fill}
            d="M794.7,125.1l-23.2-35.3l23-35c0.6-0.9,0.3-2.2-0.6-2.8c-0.3-0.2-0.7-0.3-1.1-0.3h-15.5
          c-1.2,0-2.3,0.6-2.9,1.6L760.9,76l-13.5-22.6c-0.6-1-1.7-1.6-2.9-1.6h-15.5c-1.1,0-2,0.9-2,2c0,0.4,0.1,0.8,0.3,1.1l23,35
          l-23.2,35.3c-0.6,0.9-0.3,2.2,0.6,2.8c0.3,0.2,0.7,0.3,1.1,0.3h15.5c1.2,0,2.3-0.6,2.9-1.6l13.8-23l13.8,23c0.6,1,1.7,1.6,2.9,1.6
          H793c1.1,0,2-0.9,2-2C795,125.9,794.9,125.5,794.7,125.1z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M93.9,1.1C44.8,1.1,5,40.9,5,90s39.8,88.9,88.9,88.9s88.9-39.8,88.9-88.9C182.8,40.9,143,1.1,93.9,1.1z
           M136.1,111.8c-30.4,30.4-84.7,20.7-84.7,20.7s-9.8-54.2,20.7-84.7C89,30.9,117,31.6,134.7,49.2S153,94.9,136.1,111.8L136.1,111.8
          z"
          />
          <polygon
            fill={fill}
            points="104.1,53.2 95.4,71.1 77.5,79.8 95.4,88.5 104.1,106.4 112.8,88.5 130.7,79.8 112.8,71.1     "
          />
        </g>
      </g>
    </g>
  );
}

/**
 * @typedef MapboxAttributionProps
 * @property {number} width outer SVG canvas width
 * @property {number} height outer SVG canvas height
 * @property {boolean=} isStatic if this is a static SVG render, whether
 *   or not to hide the "Improve this map" link
 * @property {string} logoColor
 * @property {string} textColor
 * @property {number} padding
 * @property {number} fontSize
 */

/**
 * @param {MapboxAttributionProps} props
 * @return {import('react').ReactElement}
 */
function MapboxAttribution({ logoColor, textColor, width, height, isStatic, padding, fontSize }) {
  const y = height - padding;

  return (
    <g className="mapbox-attribution">
      <MapboxLogo fill={logoColor} x={padding} y={y} height={Math.floor(fontSize * 1.5)} />
      <text y={y} x={width - padding} textAnchor="end" xmlnsXlink="http://www.w3.org/1999/xlink">
        <a href="https://www.mapbox.com/about/maps/">
          <tspan fill={textColor} dx="0" fontSize={fontSize}>
            © Mapbox
          </tspan>
        </a>
        <a href="https://www.openstreetmap.org/copyright">
          <tspan fill={textColor} dx="10" fontSize={fontSize}>
            © OpenStreetMap
          </tspan>
        </a>
        {!isStatic && (
          <a href="https://www.mapbox.com/map-feedback/">
            <tspan fill={textColor} dx="10" fontSize={fontSize}>
              Improve this map
            </tspan>
          </a>
        )}
      </text>
    </g>
  );
}

export default MapboxAttribution;
