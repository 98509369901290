/**
 * Intermediate representation of an activity from various data sources (.fit, .gpx, .csv)
 * @typedef Activity
 * @property {number=} startTime milliseconds
 * @property {number=} movingDuration milliseconds
 * @property {number=} totalDuration milliseconds
 * @property {string=} title
 * @property {string=} description
 * @property {Segment[]} segments
 * @property {Marker[]} markers
 */

/**
 * @typedef {
     [long: number, lat: number]
     |[long: number, lat: number, timestamp: Date]
    } Coordinate a (long, lat) and optional timestamp
    The ordering like this is to help me remember the order for GeoJSON
 */

/**
 * @typedef {"active"|"filler"|"shared"} SegmentType
 */

/**
 * @typedef Segment
 * @property {Coordinate[]} coordinates
 * @property {SegmentType} type
 */

/**
 * @typedef {"start"|"stop"|"lap"|"shared_start"|"shared_stop"|"unknown"} MarkerType
 */

/**
 * @typedef Marker
 * @property {MarkerType} type
 * @property {Coordinate} coordinate
 */

/**
 * @typedef CoordinateProps
 * @property {number} long
 * @property {number} lat
 * @property {Date=} timestamp
 */

/**
 * @param {CoordinateProps} props
 * @return {Coordinate}
 */
function toCoordinate({ long, lat, timestamp }) {
  // invalid dates convert to NaN which is falsy
  if (timestamp && +timestamp) {
    return [long, lat, timestamp];
  }
  return [long, lat];
}

/**
 * Maybe this means the activity should be structured differently
 * @param {Activity} activity
 * @return {Activity}
 */
function activityWithoutSharedSegments({ segments, markers = [] }) {
  return {
    segments: segments.filter(({ type }) => type !== "shared"),
    markers: markers.filter(({ type }) => type !== "shared_stop" && type !== "shared_start"),
  };
}

/**
 * @param {Activity} activity
 * @return {boolean}
 */
function activityHasTimestamps({ segments }) {
  return segments.every(({ coordinates }) => coordinates.every(([, , timestamp]) => !!timestamp));
}

export { toCoordinate, activityWithoutSharedSegments, activityHasTimestamps };
