/* eslint-disable camelcase */
import FitParser from "fit-file-parser";
import { toCoordinate } from "./activity.js";
import { last } from "./util.js";

/**
 * The fit-file-parser module exports a literal property named default and
 * this is the best way I could figure out how to get Typescript to just use the constructor
 * @param {import('fit-file-parser').FitParserOptions} options
 * @return {import('fit-file-parser').FitParser}
 */
function fitParser(options) {
  /* eslint-disable new-cap */
  if (FitParser.default) {
    return new FitParser.default(options);
  }

  return new FitParser(options);
}

/**
 * @param {ArrayBuffer} content
 * @return {Promise<import('fit-file-parser').Fit>}
 */
function parseFit(content) {
  /** @type {import('fit-file-parser').FitParserOptions} */
  const options = {
    force: true,
    speedUnit: "m/s",
    lengthUnit: "m",
    temperatureUnit: "celsius",
    elapsedRecordField: true,
    mode: "list",
  };

  return new Promise((resolve, reject) => {
    fitParser(options).parse(content, (error, data) => {
      if (error) {
        reject(error);
      } else if (data !== null) {
        resolve(data);
      }
    });
  });
}

/**
 * Splits a FIT into a segments (when the watch was started, stppped)
 * @param {import('fit-file-parser').Fit} fit
 * @return {import('./activity.js').Segment[]}
 */
function segments(fit, fillGaps = true) {
  /** @type {import('./activity.js').Segment[]} */
  const arr = [];

  let eventStartIdx = fit.events.findIndex(({ event_type }) => event_type === "start");

  while (eventStartIdx > -1 && eventStartIdx < fit.events.length) {
    /* eslint-disable no-loop-func */
    const eventEndIdx = fit.events.findIndex(
      ({ event_type }, i) => i > eventStartIdx && event_type === "stop_all"
    );

    if (eventEndIdx > -1) {
      const { timestamp: eventStart } = fit.events[eventStartIdx];
      const { timestamp: eventEnd } = fit.events[eventEndIdx];
      const recordStartIdx = fit.records.findIndex(({ timestamp }) => +timestamp === +eventStart);
      const recordEndIdx = fit.records.findIndex(({ timestamp }) => +timestamp === +eventEnd);

      if (recordStartIdx === -1 || recordEndIdx === -1) {
        eventStartIdx = eventEndIdx;
        /* eslint-disable no-continue */
        continue;
      }

      /**
       * @type {import('./activity.js').Coordinate[]}
       */
      const coordinates = [];

      fit.records
        .slice(recordStartIdx, recordEndIdx)
        .forEach(({ position_long, position_lat, timestamp }) => {
          if (position_long && position_lat) {
            coordinates.push(
              toCoordinate({
                long: position_long,
                lat: position_lat,
                timestamp,
              })
            );
          }
        });

      if (fillGaps) {
        const lastSegment = last(arr);
        const lastCoordinate = lastSegment && last(lastSegment.coordinates);
        if (lastCoordinate && coordinates[0]) {
          arr.push({
            coordinates: [lastCoordinate, coordinates[0]],
            type: "filler",
          });
        }
      }

      arr.push({ coordinates, type: "active" });
    }

    eventStartIdx = eventEndIdx;
  }

  return arr;
}

/**
 * @param {string} eventType
 * @return {import('./activity.js').MarkerType}
 */
function toMarkerType(eventType) {
  switch (eventType) {
    case "start":
      return eventType;
    case "marker":
      return "lap";
    case "stop_all":
      return "stop";
    default:
  }
  return "unknown";
}

/**
 * @param {import('fit-file-parser').Fit} fit
 * @return {import('./activity.js').Marker[]}
 */
function markers(fit) {
  /** @type {import('./activity.js').Marker[]} */
  const arr = [];

  fit.events.forEach(({ event_type, timestamp }) => {
    if (event_type === "start" || event_type === "stop_all" || event_type === "marker") {
      let recordIdx = fit.records.findIndex((r) => +r.timestamp === +timestamp);

      if (recordIdx > 0) {
        recordIdx -= 1; // lines things up better with the segments :shrug:
      }

      if (recordIdx < fit.records.length) {
        const record = fit.records[recordIdx];

        if (record && record.position_long && record.position_lat) {
          arr.push({
            type: toMarkerType(event_type),
            coordinate: toCoordinate({
              long: record.position_long,
              lat: record.position_lat,
              timestamp,
            }),
          });
        }
      }
    }
  });

  return arr;
}

/**
 * @param {import('fit-file-parser').Fit} fit
 * @return {import('./activity.js').Activity}
 */
function fromFit(fit) {
  let startTime;
  let movingDuration;
  let totalDuration;
  if (fit.sessions.length) {
    const session = fit.sessions[0];
    startTime = session.start_time;
    movingDuration = session.total_timer_time;
    totalDuration = session.total_elapsed_time;
  }

  return {
    segments: segments(fit),
    markers: markers(fit),
    startTime: startTime?.valueOf(),
    movingDuration,
    totalDuration,
  };
}

/**
 * @param {import('./util.js').BufferLike} content
 * @return {Promise<import('./activity.js').Activity>}
 */
function parseFitToActivity(content) {
  let promise;
  if (typeof File !== "undefined" && content instanceof File) {
    promise = content.arrayBuffer().then((arrayBuffer) => parseFit(arrayBuffer));
  } else if (
    content instanceof ArrayBuffer ||
    (typeof Buffer !== "undefined" && content instanceof Buffer)
  ) {
    promise = parseFit(content);
  } else {
    throw new Error(`unknown input type ${content.constructor}`);
  }
  return promise.then((fit) => fromFit(fit));
}

export { parseFitToActivity, parseFit, fromFit };
