/**
 * @typedef {import('./fetch.js').WithFetch} WithFetch
 */

/**
 * @param {string} templateStr
 * @return {(params: Record<string,string>)=>string}
 */
function template(templateStr) {
  const fn = (/** @type Record<string,string> */ params) =>
    Object.entries(params).reduce(
      (path, [paramName, paramValue]) => path.replace(`:${paramName}`, paramValue),
      templateStr
    );

  fn.toString = () => templateStr;

  return fn;
}

const ENDPOINTS = {
  INDEX: "/",
  GARMIN: {
    REQUEST_TOKEN: "/api/garmin/oauth/request_token",
    ACCESS_TOKEN: "/api/garmin/oauth/access_token",
    PROXY: "/api/garmin/proxy",
    CALLBACK: "/garmin/callback",
    LIST: "/garmin/",
    DETAIL: template("/garmin/:activityId"),
  },
  STRAVA: {
    ACCESS_TOKEN: "/api/strava/oauth/access_token",
    CALLBACK: "/strava/callback",
    LIST: "/strava/",
    DETAIL: template("/strava/:activityId"),
  },
};

/**
 * @param {WithFetch} params
 * @return {Promise<import('./garmin.js').RequestTokenResponse>}
 */
function garminRequestToken({ fetch }) {
  return fetch(ENDPOINTS.GARMIN.REQUEST_TOKEN, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  }).then((response) => response.json());
}

/**
 * @typedef GarminAccessTokenRequest
 * @property {string} requestToken
 * @property {string} requestTokenSecret
 * @property {string} oauthVerifier
 */

/**
 * @param {GarminAccessTokenRequest & WithFetch} params
 * @return {Promise<import('./garmin.js').AccessTokenResponse>}
 */
function garminAccessToken({ fetch, requestToken, oauthVerifier, requestTokenSecret }) {
  return fetch(ENDPOINTS.GARMIN.ACCESS_TOKEN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      requestToken,
      requestTokenSecret,
      oauthVerifier,
    }),
  }).then((response) => response.json());
}

/**
 * @template {(...args: any[]) => any} T
 *
 * @param {string} source Click source.
 *
 * @return {(fn: T) => (...args: Parameters<T>) => ReturnType<T>}
 */

/**
 * @param {import('./fetch.js').Fetch} fetch
 * @param {string} accessToken
 * @param {string} accessTokenSecret
 * @return {import('./fetch.js').Fetch} fetch
 */
function garminProxyFetch(fetch, accessToken, accessTokenSecret) {
  return (url, options) =>
    fetch(ENDPOINTS.GARMIN.PROXY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        method: options.method,
        accessToken,
        accessTokenSecret,
        url,
        headers: options.headers,
        body: options.body,
      }),
    });
}

/**
 * @typedef StravaAccessTokenRequest
 * @property {string} code
 */

/**
 * @param {StravaAccessTokenRequest & WithFetch} params
 */
function stravaAccessToken({ fetch, code }) {
  return fetch(ENDPOINTS.STRAVA.ACCESS_TOKEN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ code }),
  }).then((response) => response.json());
}

export { garminRequestToken, garminAccessToken, garminProxyFetch, stravaAccessToken, ENDPOINTS };
