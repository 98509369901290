/**
 * @typedef DurationProps
 * @property {Date} startTime
 * @property {Date} endTime
 * @par
 */

/**
 * @param {DurationProps} props
 * @return {number} milliseconds
 */
function duration({ startTime, endTime }) {
  return endTime.valueOf() - startTime.valueOf();
}

export { duration };
