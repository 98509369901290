import React, { useEffect, useRef, useState } from "react";
import { scaleLinear } from "d3-scale";
import { max, quantile, bisector } from "d3-array";
import { axisBottom, axisRight } from "d3-axis";
import { select } from "d3-selection";

/**
 * @typedef {import('../lib/activity.js').Activity} Activity
 * @typedef {import('../lib/activity.js').Coordinate} Coordinate
 */

/**
 * Based on https://stackoverflow.com/a/48805273
 * @param {Coordinate} coordA
 * @param {Coordinate} coordB
 * @return {number} meters
 */
function distance([lon1, lat1], [lon2, lat2]) {
  const toRadian = /** @type {(angle: number) => number} */ (angle) => (Math.PI / 180) * angle;
  const dist = /** @type {(a: number, b: number) => number} */ (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_M = 6_371_000;

  const dLat = dist(lat2, lat1);
  const dLon = dist(lon2, lon1);

  const lat1Radians = toRadian(lat1);
  const lat2Radians = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.sin(dLon / 2) ** 2 * Math.cos(lat1Radians) * Math.cos(lat2Radians);
  const c = 2 * Math.asin(Math.sqrt(a));

  return c * RADIUS_OF_EARTH_IN_M;
}

/**
 * Based on https://stackoverflow.com/a/48805273
 * @param {Coordinate} coordA
 * @param {Coordinate} coordB
 * @return {number} m/s
 */
function velocity(coordA, coordB) {
  const [, , timestampA] = coordA;
  const [, , timestampB] = coordB;
  const seconds = ((timestampB?.valueOf() || 0) - (timestampA?.valueOf() || 0)) / 1000;
  return distance(coordA, coordB) / seconds;
}

const METERS_TO_UNITS = {
  yd: 1.09361,
  m: 1,
  km: 0.001,
  mi: 0.000621371,
};

/**
 * @param {number} v m/s
 * @param {number} dist meters, ex 100
 * @param {'yd'|'m'} unit
 * @returns {number} ex s/100yd
 */
function pace(v, dist, unit = "yd") {
  return (dist / v) * METERS_TO_UNITS[unit];
}

/**
 * @param {number} v m/s
 */
function mph(v) {
  return v * METERS_TO_UNITS.mi * 3600;
}

/**
 * @param {number} v m/s
 */
function kph(v) {
  return v * METERS_TO_UNITS.km * 3600;
}

/**
 *
 * @param {number} number
 * @param {number} numDecimals
 * @return {number}
 */
function round(number, numDecimals) {
  const scale = 10 ** numDecimals;
  return Math.round(number * scale) / scale;
}

/**
 * @param {number} seconds
 * @return {string}
 */
function formatDuration(seconds) {
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return [
    hours && `${hours}h`,
    minutes && `${minutes}m`,
    remainingSeconds && `${remainingSeconds}s`,
  ]
    .filter(Boolean)
    .join("");
}

/**
 * @typedef PointVelocity
 * @property {Date} timestamp
 * @property {number} velocity m/s
 * @property {number} elapsedDuration seconds
 * @property {number} pace s/100yd (for now)
 * @property {number} mph mi/hr
 * @property {number} kph km/hr
 */

/**
 * @param {Activity} activity
 * @return {PointVelocity[]}
 */
function pointVelocities({ segments }) {
  const startTimestamp = segments[0].coordinates[0][2] || new Date();

  return segments.flatMap(({ coordinates }) => {
    const numCoordinates = coordinates.length;
    const velocities = [];
    for (let i = 0; i < numCoordinates - 1; ++i) {
      const coordA = coordinates[i];
      const coordB = coordinates[i + 1];
      const [, , timestamp] = coordB;
      const instant = timestamp || new Date();
      const v = velocity(coordA, coordB);
      velocities.push({
        velocity: v,
        pace: pace(v, 100, "yd"),
        mph: mph(v),
        kph: kph(v),
        timestamp: instant,
        elapsedDuration: (instant.valueOf() - startTimestamp.valueOf()) / 1000,
      });
    }

    return velocities;
  });
}

/**
 * @typedef AxisProps
 * @property {import('d3-axis').Axis<any>} axis
 * @property {string=} transform
 */

/**
 * @param {AxisProps} props
 * @return {JSX.Element}
 */
function Axis({ axis, transform }) {
  const ref = useRef(/** @type SVGGElement? */ (null));

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axis);
    }
  });

  return <g ref={ref} transform={transform} />;
}

/**
 * @typedef ChartProps
 * @property {Activity} activity
 * @property {number} width
 */

/**
 * @param {ChartProps} props
 * @return {JSX.Element}
 */
function Chart({ activity, width }) {
  const [pointerX, setPointerX] = useState(/** @type number | undefined */ (undefined));
  const ref = useRef(/** @type SVGSVGElement | null */ (null));

  const height = 100;
  const margin = {
    top: 10,
    bottom: 30,
    right: 50,
    left: 10,
  };
  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const velocities = pointVelocities(activity);
  const totalDuration = max(velocities, (d) => d.elapsedDuration) || 0;

  const velocityUnit = "mph";
  const p95velocity = /** @type number */ (quantile(velocities, 0.95, (d) => d[velocityUnit]));

  const x = scaleLinear().domain([0, totalDuration]).range([0, innerWidth]);
  const y = scaleLinear().domain([0, p95velocity]).range([innerHeight, 0]);

  /** @type number | undefined */
  let highlightedVelocityIdx;
  /** @type PointVelocity | undefined */
  let highlightedVelocity;
  if (pointerX) {
    highlightedVelocityIdx = bisector((/** @type PointVelocity */ d) => d.elapsedDuration).left(
      velocities,
      x.invert(pointerX)
    );
    highlightedVelocity = velocities[highlightedVelocityIdx];
  }

  const intervalSeconds = ((/** @type number */ duration) => {
    if (duration > 7 * 24 * 60 * 60) {
      return 24 * 60 * 60;
    }
    if (duration > 12 * 60 * 60) {
      return 90 * 60;
    }
    if (duration > 6 * 60 * 60) {
      return 30 * 60;
    }
    return 15 * 60;
  })(totalDuration);

  const ticks = [];
  for (let t = 0; t < totalDuration; t += intervalSeconds) {
    ticks.push(t);
  }
  /** @type React.PointerEventHandler */
  const onPointerMove = (event) => {
    if (ref?.current) {
      const domRect = ref.current.getBoundingClientRect();
      setPointerX(event.clientX - domRect.left - margin.left);
    }
  };

  return (
    <>
      <svg
        ref={ref}
        height={height}
        width={width}
        viewBox={`0 0 ${width} ${height}`}
        onPointerMove={onPointerMove}
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          {velocities.map((d, i) => (
            <circle
              cx={x(d.elapsedDuration)}
              cy={y(d[velocityUnit])}
              r={i === highlightedVelocityIdx ? 3 : 1}
            />
          ))}
        </g>
        <Axis
          axis={axisBottom(x)
            .tickFormat((d) => formatDuration(Number(d)))
            .tickValues(ticks)}
          transform={`translate(${margin.left}, ${margin.top + innerHeight})`}
        />
        <Axis
          axis={axisRight(y).ticks(4)}
          transform={`translate(${margin.left + innerWidth}, ${margin.top})`}
        />
        <text
          x={margin.left + innerWidth}
          y={margin.top + innerHeight}
          fontSize="10"
          dx="25"
          dy="0.32em"
        >
          {velocityUnit}
        </text>
      </svg>
      {highlightedVelocity && (
        <div>
          <div>
            Velocity:
            {round(mph(highlightedVelocity.velocity), 2)}
            {velocityUnit}
          </div>
          <div>
            Pace:
            {highlightedVelocity.pace === Infinity ? (
              "—"
            ) : (
              <>{formatDuration(highlightedVelocity.pace)}/100yd</>
            )}
          </div>
          <div>Time: {highlightedVelocity.timestamp.toLocaleTimeString()}</div>
          <div>
            Duration in swim:
            {formatDuration(highlightedVelocity.elapsedDuration)}
          </div>
        </div>
      )}
    </>
  );
}

export default Chart;
